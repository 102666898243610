@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import 'normalize';
@import 'bootstrap/bootstrap';
@import 'icons';

@import 'libs/slick';
@import 'libs/hamburger';
@import 'libs/jquery.mhead';
@import 'libs/fancySelect';
@import 'libs/baguetteBox';
@import 'libs/card';
@import 'libs/nprogress';
@import 'libs/toastr';
@import 'libs/plyr';

@import 'page'; 
@import 'error'; 

@import 'components/header';
@import 'components/header_mobile';
@import 'components/main_slider';
@import 'components/promo';
@import 'components/comments';
@import 'components/footer';
@import 'components/sidebar_categories';
@import 'components/game_preview';
@import 'components/games_grid';
@import 'components/sorted';
@import 'components/rate';
@import 'components/auth_modal';
@import 'components/comment_modal';
@import 'components/trial_modal';
@import 'components/change_login_modal';
@import 'components/tariff_plans';
@import 'components/payment_form';
@import 'components/phone_subscribe_error';
@import 'components/player';

body {
	font-family: 'Roboto', sans-serif;
	background-color: #fff;
	overflow-y: scroll;
	-webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.page {
	overflow: hidden;
}

.container-fluid {
	max-width: 1230px;
}
 
.topbg {
	height: 420px;
	position: relative;
	@include gradient-y(#30305D, #3175BB);
	transition: background-image .3s;

	&__planets {
		content:"";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		background-image: url(../img/planets.svg), url(../img/stars.svg);
		background-repeat: no-repeat;
		background-position: 30% 0%, 50% 0; 
	}

	@include media-breakpoint-down(md) {
		height: 270px;
	}

	@include media-breakpoint-down(sm) {
		height: 250px;
	}

	@include media-breakpoint-down(xs) {
		height: 240px;
	}
}

.bg_brown {
	background-color: #F0F0F0;
	padding: 50px 0 0 0;
	// margin-top: 80px;
	@include media-breakpoint-down(xs) {
		padding-top: 30px;
	}
}

a {
	outline: none;
	cursor: pointer;
}

img {
	max-width: 100%;
}

.google_play_logo {
	img {
		height: 46px;
	}
}
input {
	
	&::-webkit-input-placeholder { 
	  opacity: 1;
	  transition: opacity .3s;
	}
	&::-moz-placeholder { 
	  opacity: 1;
	  transition: opacity .3s;
	}
	&:-ms-input-placeholder { 
	  opacity: 1;
	  transition: opacity .3s;
	}
	&:-moz-placeholder {
	  opacity: 1;
	  transition: opacity .3s;
	}
}
input:focus {
	&::-webkit-input-placeholder { 
	  opacity: 0;
	}
	&::-moz-placeholder { 
	  opacity: 0;
	}
	&:-ms-input-placeholder { 
	  opacity: 0;
	}
	&:-moz-placeholder {
	  opacity: 0;
	}
}

.btn {
	cursor: pointer!important;
}

.gray {
	color: #757575;
}
.red {
	color: $red;
}
.green {
	color: $success;
}


.close {
	outline: none!important;
}