.sorted {
	font-size: 12px;

	span {
		margin-right: 10px;
		font-size: 1em;
	}

	.dropdown {

		span {
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;
		}
	}

	.btn {
		border-color: #c8c8c8;
		font-size: 14px;
		font-weight: 500;
		background-color: #fff;

		&:hover {
			border-color: $blue;
		}

		&:after {
			content: '\e900';
			font-family: 'icomoon' !important;
			  speak: none;
			  font-style: normal;
			  font-weight: normal;
			  font-variant: normal;
			  text-transform: none;
			  line-height: 1;

			  /* Better Font Rendering =========== */
			  -webkit-font-smoothing: antialiased;
			  -moz-osx-font-smoothing: grayscale;
			border: none;
			display: inline-block;
			vertical-align: middle;
			margin-left: 0;
			width: auto;
			height: auto;
			font-size: 10px;
		}
	}

	.btn:focus, .btn.focus {
		border-color: $blue;
		box-shadow: none;
	}

	.dropdown-menu {
		font-size: 14px;
		border-radius: 6px;
		border: none;
		box-shadow: 0 0 10px 0px rgba(0,0,0,0.15);
	}

	.dropdown-item {
		color: $black;
		font-weight: 500;

		&:hover {
			background-color: #f0f0f0;
		}
	}

	@include media-breakpoint-down(sm) {
		.dropdown-menu {
			font-size: 12px;
		}

		.btn {
			font-size: 12px;
		}
	}
}