.header__mobile {
	padding: 10px 0;

	&_logo {
		position: relative;
		bottom: 2px;

		img {
			max-width: 140px;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&_search {
		padding: 5px;
		color: #fff!important;
		text-decoration: none!important;
		font-size: 20px;
		
	}

	&_sticky {
		position: fixed;
		z-index: 555;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: #fff;
		text-align: center;
		box-shadow: 0 0 20px rgba(0,0,0,0.2);
		transition: transform .3s;
		transform: translateY(100%);
		display: none;

		@include media-breakpoint-down(md) {
			display: block;
		}

		.row {
			margin-left: -10px;
			margin-right: -10px;

			.col {
				padding-left: 10px;
				padding-right: 10px;
				padding-top: 15px;
				padding-bottom: 12px;
			}
		}

		a {
			color: #000;
			display: block;
			text-decoration: none;

			&.active {
				color: $blue;
			}
		}

		i {
			font-size: 24px;
			height: 24px;
			display: block;
			margin-left: auto;
			margin-right: auto;

			&.icon-search {
				font-size: 22px;
			}
		}

		span {
			font-size: 10px;
			display: block;
			margin-top: 5px;
		}
		.hamburger {
			padding: 0;

			&-box {
				width: 24px;
				height: 24px;
				display: block;
				margin-left: auto;
				margin-right: auto;
			}

			&-inner {
				bottom: 2px;

				// &:after {
				// 	top: -18px;
				// }
				// &:before {
				// 	top: -9px;
				// }
			}
		}
		.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
			background-color: #000;
			width: 24px;
			height: 2px;
		}
	}
}

#menu {
	display: none;
	background-color: #EFEFEF;
}

#panel-language, #panel-menu {
	padding-top: 0!important;

	.mm-navbar {
		display: none!important;
	}
}

.mm-wrapper_opened .hamburger-inner {
	transform: translate3d(0, -10px, 0) rotate(-45deg);
	transition-delay: 0.22s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.mm-wrapper_opened .hamburger-inner::after {
	top: 0;
	opacity: 0;
	transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.mm-wrapper_opened .hamburger-inner::before {
	top: 0;
	transform: rotate(-90deg);
	transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.mm-menu {
	background-color: #fff;
}

.mm-panels {
	top: 60px!important;
}

.mm-panel:after, .mm-panel:before {
	display: none;
}

.mm-listview {
	padding-top: 10px;
	margin-top: 0!important;
	background-color: #fff;
	margin-bottom: 0!important;

	&__nav {
		padding-bottom: 20px; 
	}

	&__controls {
		background-color: #EFEFEF;

		.icon-serviceterms {
			display: none;
		}
	}

	&__categories {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 15px;

		> li {
			width: 50%;

			i {
				position: relative;
				bottom: 1px;
			}
		}
	}

	> li {
		
		&.lats_white {
			margin-bottom: 20px;
		}

		&.bg-brown {
			background-color: #EFEFEF;
		}

		> a, > span {
			padding-top: 15px;
			padding-bottom: 15px;
			display: flex;
			align-items: center;
			font-size: 14px;
			padding-left: 25px;
			color: #000!important;
			

			&.active {
				background-color: darken(#fff, 6%);
			}

			@media(max-height: 500px) {
				padding-top: 10px;
				padding-bottom: 10px;
				font-size: 12px;
			}

			i {
				font-size: 22px;
				color: $blue;
				width: 22px;
				text-align: center;

				margin-right: 10px;

				&.icon-onlinegames {
					position: relative;
					bottom: 3px;
				}

				&.icon-allgames {
					position: relative;
					bottom: 2px;
				}

				&.icon-androidgames {
					position: relative;
					bottom: 2px;
				}

				&.icon-video {
					font-size: 20px;
					position: relative;
					bottom: 1px;
				}

				&.icon-categories {
					font-size: 20px;
					position: relative;
					bottom: 1px;
				}
				
				&.icon-user_login {
					background-color: #fff;
					width: 30px;
					height: 30px;
					border-radius: 50%;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					position: relative;
					bottom: 1px;
				}

				&.icon-logout {
					width: 30px;
					font-size: 18px;
					text-align: center;
					position: relative;
					left: 2px;
					color: $red;
				}


				@media(max-height: 500px) {
					font-size: 18px;

					&.icon-categories {
						font-size: 16px;
					}
				}
			}
		}
	}
}

.mm-navbar_tabs {
	display: flex;
	justify-content: space-between;
	border: none!important;
	padding-left: 20px!important;
	padding-right: 20px!important;
	height: 60px;
	align-items: center;
	background-color: #fff!important;
	
	a {
		color: #000!important;
		background-color: #fff!important;
		font-size: 16px;
		flex: none!important;
		border: none!important;
		display: flex;
		align-items: center;

		i {
			margin-right: 10px;
			position: relative;
			bottom: 1px;
			font-size: 20px;
		}

		&.mm-navbar__tab_selected {
			color: $blue!important;
			margin-bottom: 0!important;
		}
	}
}
.mm-navbars_top {
	border-bottom: none;

}
.mm-navbar {
	padding-left: 5px;
	border: none;
	background-color: #fff;
}

.mm-navbar__title {
	padding-left: 20px;
	display: flex;
	align-items: center;
	color: #000!important;
	text-transform: capitalize;
	text-align: left;
	font-size: 14px;

	i {
		font-size: 20px;
		color: $blue;
		margin-right: 10px;

		&.icon-serviceterms {
			position: relative;
			bottom: 1px;
		}
	}

	.icon-categories {
		position: relative;
		bottom: 1px;
	}
}

.mm-btn_prev {
	right: 0;
	left: auto!important;

	&:before {
		left: 17px;
	}

	&:before, &:after {
		width: 7px;
		height: 7px;
	}
}

.mm-navbars_bottom {
	background-color: #EFEFEF;

	a {
		color: #000!important;
		border-left: 1px solid #D6D6D7;
		font-size: 12px;

		&:first-child {
			border-left: none;
		}
	}
}

#search {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.8);
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 30px;
	visibility: hidden;
	opacity: 0;
	transition: opacity .5s;

	.close {
		position: absolute;
		right: 18px;
		top: 18px;
		color: #fff;
		opacity: 1;
		font-size: 22px;
		outline: none;
	}

	.form-control {
		min-width: 250px;
		font-size: 16px;
		border-bottom-color: #ccc;
		color: #fff;
		

		&::-webkit-input-placeholder { 
		  color: darken(#fff, 15%);
		}
		&::-moz-placeholder { 
		  color: darken(#fff, 15%);
		}
		&:-ms-input-placeholder { 
		  color: darken(#fff, 15%);
		}
		&:-moz-placeholder {
		  color: darken(#fff, 15%);
		}
	}

	.search {
		transform: translateY(100px);
		transition: transform .6s;
	}

	&.active {
		visibility: visible;
		opacity: 1;

		.search {
			transform: translateY(0px);
		}
	}
}

.fixed__nav {
	.header__mobile_sticky {
		transform: translateY(0);
	}
}

.header__modal_logo {
	visibility: hidden;
	position: absolute;
	top: -8px;
	left: 50%;
	transform: translateX(-50%);
	width: 100px;
	opacity: 0;
	transition: opacity 2s;
}

.authModal-open {
	@include media-breakpoint-down(sm) {
		
		.page {
			padding-top: 61px;
		}

		.topbg {
			margin-top: -61px;
		}

		.header__compact {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1051;
		}

		.header__mobile_sticky {
			transform: translateY(100%);
		}

		.header__modal_logo {
			visibility: visible;
			opacity: 1;
		}
		.modal-backdrop {

		}
		.header__mobile {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1051;
			background-color: #34395F;

			&_logo {
				opacity: 0;
				display: block;
			}
		}
	}
	
}