.modal-change-login {

	.modal-dialog {
		max-width: 550px;
	}

	.modal-header {
		padding-left: 40px;
		padding-right: 40px;

		@include media-breakpoint-down(xs) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.modal-body {
		padding-left: 40px;
		padding-right: 40px;
		padding-bottom: 40px;

		@include media-breakpoint-down(xs) {
			padding-left: 15px;
			padding-right: 15px;
			padding-bottom: 30px;
		}
	}
	
	p {
		font-size: 14px;
		margin-bottom: 20px;

		@include media-breakpoint-down(xs) {
			font-size: 12px;
		}
	}

	.form-label-animation {
		margin-bottom: 20px;
	}

	.modal-auth__socials {
		margin-bottom: 30px;
		padding-top: 10px;

		@include media-breakpoint-down(xs) {
			margin-bottom: 20px;
			padding-top: 5px;
		}
	}

	.btn-outline-primary {
		border-width: 2px;
		display: inline-flex;
		align-items: center;
		font-size: 14px;

		@include media-breakpoint-down(xs) {
			margin-left: auto;
			margin-right: auto;
			display: flex;		
		}

		span {
			color: #000;
			transition: color .3s;
		}

		i {
			margin-right: 7px;
			font-size: 1.2em;
		}

		&:hover {
			span {
				color: #fff;
			}
		}
	}
}