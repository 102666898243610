.sidebar {
	h3 {
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
	}
	
	.search {
		margin-bottom: 40px;

		button {
			color: #000;

			&:hover {
				color: $blue;
			}
		}

		.form-control {
			border-bottom-color: #C8C8C8;
			line-height: 2.2;
			color: #000;
			padding-right: 40px;
			padding-left: 0;



			&::-webkit-input-placeholder { 
			  color: #aaaaaa;
			}
			&::-moz-placeholder { 
			  color: #aaaaaa;
			}
			&:-ms-input-placeholder { 
			  color: #aaaaaa;
			}
			&:-moz-placeholder {
			  color: #aaaaaa;
			}

			&:focus {
				box-shadow: none;
				border-bottom-color: $blue;
			}
		}
	}

	&__categories {
		max-width: 220px;
		padding-top: 10px;

		h3 {
			margin: 0;
			padding-bottom: 10px;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin: 10px 0 0 -10px;

				a {
					color: #000;
					font-size: 14px;
					font-weight: 500;
					text-decoration: none;
					display: flex;
					align-items: center;
					padding: 10px 0 10px 10px;
					transition: background-color .3s;

					&:hover, &.active {
						background-color: #f2f2f2;
					}

					i {
						font-size: 26px;
						color: #4664FE;
						width: 30px;
						margin-right: 15px;
						text-align: center;

						&.icon-casual {
							font-size: 22px;
						}

						&.icon-arcade {
							font-size: 24px;
						}

						&.icon-racing {
							font-size: 24px;
						}
					}
				}
			}
		}

		&_video {
			ul {
				li {
					a {
						padding-left: 25px;
					}
				}
			}
		}
	}

	&__games {
		max-width: 220px;

		&_wrap {
			padding-left: 10px;
			margin-bottom: 40px;
		}

		&_btn {
			padding: 15px 0;

			.btn {
				font-size: 14px;
				color: #000;
				border-width: 2px;

				&:hover {
					color: #fff;
				}
			}
		}

		h3 {
			margin-bottom: 20px;
		}
	}
}

.sidebar {
	&__game {
		margin-bottom: 15px;
		display: flex;
		
		&_image {
			min-width: 62px;
			width: 62px;
			height: 62px;
			position: relative;
			overflow: hidden;
			border-radius: 5px;
			margin-right: 15px;

			img {
				position: absolute;
				min-width: 100%;
				min-height: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		&_info {
			&__title {
				font-size: 14px;
				color: #000;
				font-weight: 500;
				margin-top: 3px;
				line-height: 1.2em;
				margin-bottom: 4px;
				display: block;
			}
		}
	}
}

