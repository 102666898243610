.rate {
	display: inline-flex;
	align-items: center;
	padding-right: 5px;

	> .icon-star {
		position: relative;
		bottom: 1px;
		color: #FFB821;
		margin-right: 3px;

	}

	&__value {
		font-size: 14px;
		color: #414141;
		font-weight: bold;

		@include media-breakpoint-down(xs) {
			font-size: 12px;
		}
	}

	&__count {
		margin-left: 10px;
		color: #9e9e9e;
		font-size: 12px;

		@include media-breakpoint-down(xs) {
			display: none;
		}
		
		i {
			font-size: 12px;
			margin-right: 3px;
		}

		span {
			
			
		}
	}
}