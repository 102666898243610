.promo {
	display: block;
	width: 100%;
	margin-bottom: 80px;
	font-size: 12px;

	&_info {

		&-title {
			font-size: 1.35em;
			margin-bottom: 15px;
			text-transform: uppercase;
			font-weight: 700;
		}

		&-text {
			font-size: 1em;
			margin-bottom: 30px;
			color: #757575;
		}

		.btn {
			color: $black;
			font-size: 14px;
			border-width: 2px;

			&:hover {
				color: $white;
			}
		}
	}

	&_slider {
		opacity: 0;
		margin-left: -5px;

		&.slick-initialized {
			opacity: 1;

			.game_preview {
				display: block;
			}
		}

		.game_preview {
			display: none;
		}

		.slick-list {
			padding: 10px 0 10px 5px !important;
			margin: -10px 0 -10px -5px !important;
		}

		.slick-arrow {
			display: block;
			position: absolute;
			width: 50px;
			height: 110%;
			background-color: $white;
			font-size: 24px;
			border: none;
			padding: 0;
			margin: 0;
			text-align: center;
			cursor: pointer;
			outline: none;
			color: #ccc;
			transition: all 0.2s linear 0s;
			top: -10px;
			z-index: 100;

			&:hover {
				color: $black;
			}
		}

		.slick-next {
			left: 100%;
		}

		.slick-prev {
			right: 100%;
			background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
		}

		.slick-disabled {
			display: none !important;
		}

		.game_preview {
			margin-bottom: 0;
		}
	}

	&_category {
		margin-bottom: -15px;
		margin-left: -5px;

		&-items {
			margin: 0 -5px;

			.col {
				max-width: 16.66%;
				flex: 0 0 16.66%;
			}
		}

		&-item {
			border-radius: 6px;
			box-shadow: 0 0 10px rgba(0,0,0,0.15);
			margin: 0 5px 15px;
			padding: 10px 15px;
			height: 84px;
			cursor: pointer;
			transition: all 0.2s linear 0s;
			background-color: $white;
			color: #030303;
			white-space: nowrap;
			position: relative;
			display: flex;
			align-items: center;
			transform: translateY(0);

			&:hover {
				text-decoration: none;
				color: #030303;

				i {
					color: $blue;
				}
			}

			@include media-breakpoint-up(lg) {
				&:hover {
					color: $white;
					background-color: #4e5dff;
					text-decoration: none;
					transform: translateY(-5px);
					box-shadow: 0 5px 20px rgba(0,0,0,0.3);

					i {
						color: $white;
					}

					.promo_category-amount {
						color: #dadada;
					}
				}
			}


			i {
				color: $blue;
				display: inline-block;
				vertical-align: middle;
				margin-right: 10px;
				font-size: 2.5em;
				transition: all 0.2s linear 0s;
			}

			strong {
				display: inline-block;
				vertical-align: middle;
				font-size: 1.35em;
			}
		}

		&-amount {
			position: absolute;
			top: 10px;
			right: 10px;
			display: inline-block;
			color: #919191;
		}
	}
}

@include media-breakpoint-down(lg) {
	.promo_category-items {

		.col {
			max-width: 20%;
			flex: 0 0 20%;
		}
	}
}

@include media-breakpoint-down(md) {
	.promo {
		margin-bottom: 60px;
	}

	.promo_category {
		margin-left: 0;
	}
	.promo_info {
		margin-bottom: 20px;

		&-title {
			margin: 0;
		}
	}

	.promo_slider {
		.slick-list {
			overflow: visible !important;
		}
	}
}

@media (max-width: 870px) { 
	.promo_category-items {

		.col {
			max-width: 25%;
			flex: 0 0 25%;
		}
	}
}

@include media-breakpoint-down(sm) {
	.promo {
		margin-bottom: 40px;
	}
	.promo_category-items {

		.col {
			max-width: 33.33%;
			flex: 0 0 33.33%;
		}
	}

	.promo_category-item {
		height: 60px;

		i {
			font-size: 2em;
		}

		strong {
			font-size: 1.2em;
		}
	}
}

@include media-breakpoint-down(xs) {
	.promo {
		margin-bottom: 30px;
	}
	.promo_category-items {

		.col {
			max-width: 50%;
			flex: 0 0 50%;
		}
	}
}