.modal {
	&-auth {
		.modal-dialog {
			max-width: 700px;
			
		}

		.modal-content {
			min-height: 350px;
		}

		&.show {
			.form-slider-track {
				transition: opacity .8s;
			}
		}
	}
}

.modal-auth {
	
	.modal-dialog {
		@include media-breakpoint-down(sm) {
			align-items: flex-start;
			margin-top: 61px;
			margin-left: 0;
			margin-right: 0;
		}
	}

	.modal-content {
		@include media-breakpoint-down(sm) {
			border: none;
			border-radius: 0;
		}
	}
	



	&__sidebar {
		flex: 0 0 280px;
		max-width: 280px;
		@include gradient-y(#30305D, #3175BB);
		text-align: center;
		flex-direction: column;
		border-radius: 8px 0 0 8px;

		&_logo {
			margin-top: -68px;

			img {
				width: 164px;
			}
		}

		&_note {
			font-size: 20px;
			font-weight: 300;
			color: #fff;
			margin-top: 39px;
			line-height: 1.2em;
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
			display: none;
		}

		&_button {
			margin-top: 30px;
			display: none;

			a {
				
			}
		}

		&_socials {
			display: flex;
			align-items: center;
			justify-content: center;
			list-style: none;
			padding: 0;
			margin-top: 32px;

			li {
				padding: 0 10px;


				a {
					color: #fff;
					display: flex;
					align-items: center;
					transition: color .3s;

					i {
						font-size: 16px;

						&.icon-phone_login {
							font-size: 15px;
						}

						&.icon-google_login {
							font-size: 20px;
							position: relative;
							left: 2px;
						}

						&.icon-fb_login {
							font-size: 18px;
						}
					}

					&:hover {
						text-decoration: none;
						color: #f4ba11;
					}
				}
			}

		}
	}

	&__inner {
		padding: 30px 30px 40px 30px;

		@include media-breakpoint-down(sm) {
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 20px;
		}
	}


	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		position: relative;
		z-index: 2;

		h3 {
			font-size: 16px;
			font-weight: bold;
			color: #000;
			margin: 0;
			display: none;
			text-transform: uppercase;
			// opacity: 0;

			&.active {
				display: block;
				opacity: 1;
			}
		}

		.close {
			font-size: 18px;
		}
	}

	&__form {
		height: 205px;

		@include media-breakpoint-down(xs) {
			height: auto;
			
			margin-bottom: 20px;
		}

		.form-group {
			margin-bottom: 25px;
		}

		.form-control {
			@include media-breakpoint-down(xs) {
				font-size: 12px;
			}
		}

		.btn.btn-primary {
			border: 1px solid $blue!important;

			@include media-breakpoint-down(xs) {
				font-size: 14px;
			}
		}
	}

	&__socials {
		display: flex;
		align-items: center;

		@include media-breakpoint-down(sm) {
			justify-content: center;
		}

		span {
			font-size: 14px;
		}
		
		ul {
			list-style: none;
			padding: 0;
			margin: 0 0 0 15px;
			display: flex;
			align-items: center;
		}

		li {
			margin-right: 10px;


			a {
				color: #000;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: border-color .3s, color .3s;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				border: 2px solid #E8E8E8;

				i {
					font-size: 18px;

					&.icon-google_login {
						font-size: 22px;
						position: relative;
						left: 2px;
					}

					&.icon-fb_login {
						font-size: 24px;
					}
				}

				&:hover {
					text-decoration: none;
					border-color: darken(#E8E8E8, 5%);
					color: $blue;
				}
			}
		}
	}

	&__footer {
		padding: 20px 20px;
		background-color: #F4F4F4;
		text-align: center;
		font-size: 12px;

		&_row {
			display: none;

			&.active {
				display: block;
			}
		}

		a {
			font-weight: bold;
		}
	}

	.btn {
		padding: 0.5rem 1.7rem;
	}
}

.form_password_recovery .modal-auth__form {
	display: flex;
	align-items: center;
	width: 100%;
	padding-bottom: 20px;
	min-height: 167px;

	form {
		width: 100%;
	}
}



.form-label-animation {
	position: relative;

	label {
		position: absolute;
		top: 50%;
		left: 0;
		padding-left: 10px;
		transform: translateY(-50%);
		font-size: 14px;
		transition: transform .3s, padding-left .3s, font-size .3s, color .3s;
		color: #aaaaaa;
		pointer-events: none;

		&.active {
			transform: translateY(-190%);
			font-size: 12px;
			padding-left: 0;
		}

		@include media-breakpoint-down(xs) {
			font-size: 12px;
		}
	}

	input {
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 10px;
	}
}

.form-group {
	&__icon {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		padding-right: 10px;
		color: #C5C5C5;
		transition: color .3s;

		i {
			font-size: 24px;

			@include media-breakpoint-down(xs) {
				font-size: 20px;
			}

			&.icon-user_login {
				font-size: 26px;

				@include media-breakpoint-down(xs) {
					font-size: 22px;
				}

			}
		}
	}

	input:focus ~  .form-group__icon {
		color: #455DFA;
	}

	
}

.was-validated {

	input:invalid ~  .form-group__icon {
		color: #FF0D23;
	}

	input:invalid ~  label {
		transform: translateY(-190%);
	}

	input:valid ~  label {
		transform: translateY(-190%);
	}

	input:valid ~ .form-group__icon {
		color: #00A420;
	}
}

input.is-invalid ~  .form-group__icon {
	color: #FF0D23!important;
}

input.is-invalid ~  label {
	// display: none;
}

input.is-valid ~ .form-group__icon {
	color: #00A420!important;
}

.password-recovery {
	font-size: 12px;
	text-decoration: none!important;
}

.contacts {
	display: flex;
	// flex-wrap: wrap;
	margin-top: 20px;

	&__item {
		margin-right: 50px;
		padding-left: 36px;
		position: relative;
		font-size: 18px;

		@include media-breakpoint-down(sm) {
			font-size: 16px;
		}

		@include media-breakpoint-down(xs) {
			font-size: 14px;
			width: 50%;
			margin-right: 0;
			margin-bottom: 20px;
			padding-left: 30px;
		}

		@media(max-width: 350px) {
			font-size: 12px;
			padding-left: 26px;
		}

		a {
			
			color: #000;
			transition: color .3s;

			

			&:hover {
				text-decoration: none;
				color: lighten(#000, 35%);
			}
		}

		i {
			font-size: 1.4em;
			color: $blue;
			position: absolute;
			top: 2px;
			left: 0;

			@include media-breakpoint-down(xs) {
			}
		}

		span {
			display: block;
			font-size: 12px;
			font-weight: 300;
			color: #757575;
		}
	}
}



.form-slider {


	&__item {
		display: none;
		// opacity: 0;

		&.active {
			display: block;
			opacity: 1;
		}
	}
}


.form_message {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	display: flex;
	display: none;

	&__icon {
		font-size: 60px;

		@include media-breakpoint-down(xs) {
			font-size: 50px;
		}
	}

	&__note {
		font-size: 18px;
		color: #000;

		@include media-breakpoint-down(xs) {
			font-size: 14px;
		}
	}

	a {
		font-size: 12px;
		text-decoration: none!important;
		margin-top: 2px;
	}

	&__success & {
		&__icon {
			color: $green;
		}
	}

	&__error & {
		&__icon {
			color: $red;
		}
	}
}