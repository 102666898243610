.card-js input.card-number {
    // padding-right: 48px
}

.card-js .card-number-wrapper .card-type-icon {
    // height: 23px;
    // width: 32px;
    // position: absolute;
    // display: block;
    // right: 8px;
    // top: 7px;
    // background: url(https://cardjs.co.uk/img/cards.png) 0 23px no-repeat;
    // pointer-events: none;
    // opacity: 0;
    // -webkit-transition: opacity .15s linear;
    // -moz-transition: opacity .15s linear;
    // -ms-transition: opacity .15s linear;
    // -o-transition: opacity .15s linear;
    // transition: opacity .15s linear
}

.card-js .card-number-wrapper .show {
    opacity: 1
}

.card-js .card-number-wrapper .card-type-icon.visa {
    background-position: 0 0
}

.card-js .card-number-wrapper .card-type-icon.master-card {
    background-position: -32px 0
}

.card-js .card-number-wrapper .card-type-icon.american-express {
    background-position: -64px 0
}

.card-js .card-number-wrapper .card-type-icon.discover {
    background-position: -96px 0
}

.card-js .card-number-wrapper .card-type-icon.diners {
    background-position: -128px 0
}

.card-js .card-number-wrapper .card-type-icon.jcb {
    background-position: -160px 0
}

.card-js .cvc-container {
    width: 50%;
    float: right
}

.card-js .cvc-wrapper {
    box-sizing: border-box;
    margin-left: 5px
}

.card-js .cvc-wrapper .cvc {
    display: block;
    width: 100%
}

.card-js .expiry-container {
    width: 50%;
    float: left
}

.card-js .expiry-wrapper {
    box-sizing: border-box;
    margin-right: 5px
}

.card-js .expiry-wrapper .expiry {
    display: block;
    width: 100%
}

.card-js .expiry-wrapper .expiry-month {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 30px
}

.card-js .expiry-wrapper .expiry-year {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0
}

.card-js .expiry-wrapper .expiry-month,
.card-js .expiry-wrapper .expiry-year {
    display: inline-block
}

.card-js .expiry-wrapper .expiry {
    // padding-left: 38px
}

.card-js .icon {
    position: absolute;
    display: block;
    width: 24px;
    height: 17px;
    left: 8px;
    top: 10px;
    pointer-events: none
}

.card-js .icon.right {
    right: 8px;
    left: auto
}

.card-js .icon.popup {
    cursor: pointer;
    pointer-events: auto
}

.card-js .icon .svg {
    fill: #888
}

.card-js .icon.popup .svg {
    fill: #aaa!important
}

.card-js .card-number-wrapper,
.card-js .name-wrapper {
    margin-bottom: 15px;
    width: 100%
}

.card-js .card-number-wrapper,
.card-js .cvc-wrapper,
.card-js .expiry-wrapper,
.card-js .name-wrapper {

    position: relative
}

.card-js .card-number-wrapper,
.card-js .cvc-container,
.card-js .expiry-container,
.card-js .name-wrapper {
    display: inline-block
}

.card-js::after {
    content: ' ';
    display: table;
    clear: both
}

.card-js input,
.card-js select {
    // color: #676767;
    // font-size: 15px;
    // font-weight: 300;
    // font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    // height: 36px;
    // border: 1px solid #d9d9d9;
    // border-radius: 4px;
    // box-shadow: none;
    // background-color: #FDFDFD;
    // box-sizing: border-box;
    // padding: 0;
    // -webkit-transition: border-color .15s linear, box-shadow .15s linear;
    // -moz-transition: border-color .15s linear, box-shadow .15s linear;
    // -ms-transition: border-color .15s linear, box-shadow .15s linear;
    // -o-transition: border-color .15s linear, box-shadow .15s linear;
    // transition: border-color .15s linear, box-shadow .15s linear
}

.card-js select {
    -moz-appearance: none;
    text-indent: .01px;
    text-overflow: ''
}

.card-js input[disabled],
.card-js select[disabled] {
    background-color: #eee;
    color: #555
}

.card-js select option[hidden] {
    color: #ABA9A9
}

.card-js input:focus,
.card-js select:focus {
    background-color: #fff;
    outline: 0;
    border-color: #66afe9;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)
}

.card-js input[readonly=readonly]:not([disabled]),
.card-js input[readonly]:not([disabled]) {
    background-color: #fff;
    cursor: pointer
}

.card-js .has-error input,
.card-js .has-error input:focus {
    border-color: #F64B2F;
    box-shadow: none
}

.card-js input.card-number,
.card-js input.cvc,
.card-js input.name {
    // padding-left: 38px;
    width: 100%
}

.card-js.stripe .icon .svg {
    fill: #559A28
}