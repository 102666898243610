.content {
	// margin-bottom: 80px;
	padding-top: 30px;

	@include media-breakpoint-down(md) {
		padding-top: 15px;
	}

	@include media-breakpoint-down(sm) {
		padding: 30px 0 0 0;
	}

	@include media-breakpoint-down(sm) {
		margin-bottom: 0px;
	}

	&__inner {
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0 0 30px rgba(0,0,0,0.1);

		@include media-breakpoint-down(sm) {
			box-shadow: none;
		}
	} 

	.comment {
		margin-bottom: 30px;

		&__body {
			background-color: #F6F6F6;
			box-shadow: none;

			&:after {
				border-color: transparent #F6F6F6 transparent transparent;
				filter: none;
			}
		}
	}
}

.mt-6 {
	margin-top: 5rem!important;
}

.game {
	&__container {
		padding: 40px;

		@include media-breakpoint-down(sm) {
			padding: 0 0 30px 0;
		}
	}

	&__image {
		width: 210px;
		height: 210px;
		position: relative;
		overflow: hidden;
		
		margin-right: 30px;



		img {
			width: 100%;
			object-fit: cover;
			object-position: 50% 50%;
			border-radius: 8px;
			// min-width: 100%;
			// min-height: 100%;
			// object-fit: fill;
			// position: absolute;
			// top: 50%;
			// left: 50%;
			// transform: translate(-50%, -50%);
		}

		@include media-breakpoint-down(sm) {
			width: 180px;
			height: 180px;

			img {
			}
		}

		@include media-breakpoint-down(xs) {
			width: 40%;
			height: auto;
			margin-right: 20px;
		}
	} 

	&__info {
		@include media-breakpoint-down(sm) {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
		}
		@include media-breakpoint-down(xs) {
			width: 60%;
		}

		&_row {
			position: relative;
			@include media-breakpoint-down(sm) {
				order: 3;
			}


		}

		&_title {
			font-size: 32px;
			color: #000;
			font-weight: bold;
			margin-top: 5px;
			margin-bottom: 3px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

			@include media-breakpoint-down(sm) {
				font-size: 26px;
				order: 1;
			}

			@include media-breakpoint-down(xs) {
				font-size: 18px;
			}

			@media(max-width: 360px) {
				font-size: 16px;
			}
		}

		&_category {
			font-size: 12px;
			color: #4555ff;
			font-weight: bold;
			margin-right: 20px;

			@include media-breakpoint-down(xs) {
				margin-right: 15px;
			}
		}

		&_play {
			margin-top: 25px;
			margin-bottom: 25px;

			@include media-breakpoint-down(sm) {
				order: 2;
				margin-top: 13px;
				margin-bottom: 20px;

				@media(max-width: 360px) {
					margin-top: 8px;
					margin-bottom: 15px;
				}
			}

			.btn {
				padding-left: 30px;
				padding-right: 30px;
				font-size: 14px;
				display: inline-flex;
				justify-content: center;
				align-items: center;

				i {
					margin-right: 5px;
				}

				i.icon-download {
					margin-right: 10px;
				}

				@media(max-width: 360px) {
					font-size: 12px;
				}
			}
		}

		&_type {
			font-size: 12px;
			text-transform: uppercase;
			margin-right: 30px;
			color: #000;
			display: flex;
			align-items: center;
			font-weight: 500;

			i {
				font-size: 22px;
				color: #BCBCBC;
				position: relative;
				bottom: 3px;
				margin-right: 7px;
			}

			&_mobile {
				display: none;
				@include media-breakpoint-down(xs) {
					display: block;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);	

					i {
						font-size: 22px;
						color: #C0C0C0;
					}
				}
			}
		}

		&_playes {
			font-size: 12px;
			text-transform: uppercase;
			color: #000;
			display: flex;
			align-items: center;
			font-weight: 500;

			i {
				font-size: 16px;
				color: #BCBCBC;
				position: relative;
				margin-right: 6px;
			}
		}
	}

	&__slider {
		padding: 40px;
		background-color: #F6F6F6;
		position: relative;

		@include media-breakpoint-down(sm) {
			margin-left: -15px;
			margin-right: -15px;
			padding: 30px 15px;
		}

		.slick-arrow {
			position: absolute;
			top: 0;
			width: 60px;
			height: 100%;
			border: none;
			outline: none;
			cursor: pointer;
			z-index: 2;

			&__prev {
				padding-left: 15px;
				left: 0;
				text-align: left;
				background: -moz-linear-gradient(left,  rgba(246,246,246,1) 0%, rgba(246,246,246,1) 56%, rgba(246,246,246,0) 100%);
				background: -webkit-linear-gradient(left,  rgba(246,246,246,1) 0%,rgba(246,246,246,1) 56%,rgba(246,246,246,0) 100%);
				background: linear-gradient(to right,  rgba(246,246,246,1) 0%,rgba(246,246,246,1) 56%,rgba(246,246,246,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#00f6f6f6',GradientType=1 );

			}

			&__next {
				padding-right: 15px;
				text-align: right;
				right: 0;
				background: -moz-linear-gradient(left,  rgba(246,246,246,0) 0%, rgba(246,246,246,1) 44%, rgba(246,246,246,1) 100%);
				background: -webkit-linear-gradient(left,  rgba(246,246,246,0) 0%,rgba(246,246,246,1) 44%,rgba(246,246,246,1) 100%);
				background: linear-gradient(to right,  rgba(246,246,246,0) 0%,rgba(246,246,246,1) 44%,rgba(246,246,246,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f6f6f6', endColorstr='#f6f6f6',GradientType=1 );


			}
		}

		&_slide {
			margin-right: 10px;

			img {
				height: 285px;
			}

			@include media-breakpoint-down(sm) {
				img {
					height: 150px;
				}
			}
			@include media-breakpoint-down(xs) {
				margin-right: 5px;
			}

			@media(max-width: 360px) {
				img {
					height: 120px;
				}
			}
		}
	}

	&__description {
		padding: 40px;
		border-bottom: 1px solid #D6D6D6;

		@include media-breakpoint-down(sm) {
			padding: 30px 15px;
			margin-left: -15px;
			margin-right: -15px;
		}

		&_title {
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 20px;
		}

		&_text {
			font-size: 14px;
			font-weight: 300;
			overflow: hidden;
			display: block!important;
			margin-bottom: 20px;
			position: relative;

			@media(max-width: 360px) {
				font-size: 12px;
			}

			&:after {
				content:"";
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 40px;
				background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.01) 1%, rgba(255,255,255,1) 100%);
				background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.01) 1%,rgba(255,255,255,1) 100%);
				background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.01) 1%,rgba(255,255,255,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );

			}

			&.collapse, &.collapsing {
				height: 140px;

				&.show {
					height: auto;

					&:after {
						display: none;
					}
				}
			}

			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&_show-more {
			font-size: 12px;
			font-weight: bold;
			color: #4e5dff;
			display: inline-block;

			.collapsed_show {
				display: none;
			}
			&.collapsed {
				.collapsed_show {
					display: block;
				}
				.collapsed_hide {
					display: none;
				}
			}
		}
	}

	&__rating {
		padding: 40px 40px 0 40px;

		@include media-breakpoint-down(sm) {
			padding: 30px 0 0 0;
		}

		&_row {
			margin-bottom: 30px;

			@include media-breakpoint-down(xs) {
				margin-bottom: 20px;
			}
		}

		&_title {
			color: #000;
			font-weight: bold;
			font-size: 16px;
			text-transform: uppercase;
		}
 
		&_btn {
			a { 
				font-size: 14px;
				color: #000;
				display: inline-flex;
				align-items: center;
				border-width: 2px;


 
				i {
					font-size: 18px;
					margin-right: 4px;
					color: $blue;
					transition: color .3s;
					position: relative;
					bottom: 1px;
				}

				@include media-breakpoint-down(sm) {
					font-size: 11px;

					i {
						font-size: 14px;
					}
				}

				@media(max-width: 360px) {
					padding-left: 15px;
					padding-right: 15px;
				}

				&:hover {
					color: #fff;

					i {
						color: #fff;
					}
				}
			}
		}
	}

	&__comments {
		padding: 40px;

		@include media-breakpoint-down(sm) {
			padding: 20px 0 30px 0;
		}

		.comment {
			@include media-breakpoint-down(sm) {
				padding-bottom: 35px;
			}
			&__body {
				margin-top: 0;
			}

			&__created {
				@include media-breakpoint-down(sm) {
					right: auto;
					top: 100%;
					left: 0;
					transform: none;
				}
				
			}
		}

		.btn {
			color: #000;
			font-size: 14px;
			border-width: 2px;

			@include media-breakpoint-down(sm) {
				margin-top: 30px;
			}

			&:hover {
				color: #fff;
			}
		}
	}
}

.rate_circle {
	width: 60px;
	height: 60px;

	@include media-breakpoint-down(xs) {
		width: 50px;
		height: 50px;
	}
}

.game-rate {
	padding-left: 20px;
	font-weight: 500;

	@include media-breakpoint-down(xs) {
		padding-left: 15px;
	}

	&__value {
		font-size: 64px;
		line-height: .9em;

		@include media-breakpoint-down(xs) {
			font-size: 44px;
			padding-top: 3px;
		}
	}

	&__max {
		color: #9e9e9e;
		font-size: 14px;

		@include media-breakpoint-down(xs) {
			font-size: 12px;
			text-align: center;
		}
	}

	&__votes {
		color: #9e9e9e;
		font-size: 14px;
		margin-left: 10px;

		i {
			font-size: 12px;
			margin-right: 3px;
		}
	}
}

.progressbar-text {
	font-size: 20px;
	color: #EBEBEB;

	@include media-breakpoint-down(xs) {
		font-size: 16px;
	}
}

.stars {
	padding-left: 70px;

	@include media-breakpoint-down(xs) {
		padding-left: 60px;
	}

	&__item { 
		padding: 5px 0;
		position: relative;

		@include media-breakpoint-down(xs) {
			padding: 4px 0;
		}

		&_icons {
			position: absolute;
			right: 100%;
			top: 50%;
			transform: translate(0%, -50%);
			font-size: 7px;
			margin-right: 10px;
			color: #454545;
			white-space: nowrap;

			@include media-breakpoint-down(xs) {
				font-size: 6px;
			}

			i {
				margin-left: 4px;

				@include media-breakpoint-down(xs) {
					margin-left: 3px;
				}
			}
		}

		&_border {
			height: 1px;
			background-color: #DFDFDF;
			position: relative;

			&_active {
				position: absolute;
				top: 0;
				left: 0;
				height: 1px;
				width: 0px;
				background-color: #464646;
				transition: width 2s;
			}
		}
	}
}




// Profile

.profile {
	@include media-breakpoint-down(sm) {
		text-align: center;
	}

	&__top {
		padding: 40px;
		// border-bottom: 1px solid #D8D8D8;

		@include media-breakpoint-down(sm) {
			padding: 0 15px 30px 15px;
			margin-left: -15px;
			margin-right: -15px;
			border-bottom: 1px solid #DDDDDD;
		}

		&__row {
			font-size: 14px;
			margin: 6px 0;

			@include media-breakpoint-down(md) {
				font-size: 12px;
			}

			span {
				margin-right: 4px;
				vertical-align: middle;
			}

			b {
				margin-right: 4px;
				vertical-align: middle;
			}

			a {
				display: inline-flex;
				align-items: center;
				vertical-align: middle;
				padding-left: 0px;
				position: relative;
				text-decoration: none!important;
				margin-top: 3px;

				@include media-breakpoint-down(sm) {
					display: flex;
					justify-content: center;
					margin-top: 10px;

				}


				i {
					margin-right: 5px;
					position: relative;
					bottom: 1px;
					font-size: 18px;
				}
			}

			
		}
	}

	&__image {
		max-width: 155px;
		position: relative;

		@include media-breakpoint-down(md) {
			max-width: 200px;
		}

		@include media-breakpoint-down(sm) {
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
			max-width: 140px;
		}

		img {
			width: 100%;
			border-radius: 50%;
		}

		&_upload {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background-color: rgba(0,0,0, .8);
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			visibility: hidden;
			opacity: 0;
			transition: opacity .5s;



			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				display: block;
				outline: none;
				opacity: 0;
				cursor: pointer;
				z-index: 1;
			}

			&_icon {
				font-size: 34px;
				color: #fff;
				position: relative;
				width: 100%;
				text-align: center;
				line-height: 1;
			}

			&_label {
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				font-size: 11px;
				font-weight: 300;
				text-align: center;
				line-height: 1.28;
				color: #868686;
				margin-top: 5px;
			}

			@include media-breakpoint-down(sm) {
				visibility: visible;
				opacity: 1;
				background-color: transparent;

				&_icon {
					font-size: 12px;
					background-color: $blue;
					width: 35px;
					height: 35px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					position: absolute;
					text-align: center;
					top: 100%;
					left: 50%;
					transform: translate(-50%, -70%);

					i {
						display: block;
						width: 15px;
						text-align: center;

					}
				}

				&_label {
					display: none;
				}
			}
		}

		&:hover &, &:focus & {
			&_upload {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	&__login {
		

		&_text {
			font-size: 32px;
			color: #000;
			font-weight: bold;
			display: inline-block;

			@include media-breakpoint-down(md) {
				font-size: 26px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 20px;
			}
		}

		&_change_link {
			display: inline-block;
			vertical-align: top;
			font-size: 12px;
			margin-left: 10px;

			@include media-breakpoint-down(sm) {
				display: block;
				vertical-align: middle;
				margin-left: 0;
			}

			a {
				text-decoration: none!important;


			}
		}
	}
	
	&__notification {
		border: 1px dashed #BBBBBB;
		padding: 30px 30px;
		border-radius: 6px;
		margin-top: 25px;

		@include media-breakpoint-down(md) {
			padding: 20px;
		}

		

		&_text {
			font-size: 14px;
			color: #050505;

			@include media-breakpoint-down(md) {
				font-size: 12px;
			}
		}

		&_button {
			.btn {
				font-size: 14px;
				display: inline-flex;
				align-items: center;

				i {
					font-size: 20px;
					margin-right: 8px;
					position: relative;
					bottom: 1px;
				}

				@include media-breakpoint-down(sm) {
					font-size: 12px;

					i {
						font-size: 16px;
					}
				}
			}
		}

		.icon-recharge {
			font-size: 90px;
			color: #C9C9C9;
		}
	}

	&__buttons {
		margin-top: 30px;

		.btn {
			font-size: 14px;
			border-width: 2px;
			margin-right: 15px;
			display: inline-flex;
			align-items: center;

			&:last-child {
				margin-right: 0;
			}

			@include media-breakpoint-down(sm) {
				font-size: 12px;
			}

			i {
				font-size: 18px;
				vertical-align: middle;
				margin-right: 6px;

				@include media-breakpoint-down(sm) {
					font-size: 16px;
				}
			}

			span {
				display: block;
				color: #000;
				transition: color .3s;
			}

			&:hover {
				span {
					color: #fff;
				}
			}
		}
	}

	&__trial_expired {
		padding: 0 30px;
		margin-bottom: 30px;

		@include media-breakpoint-down(sm) {
			padding: 0;
			text-align: center;
			margin-bottom: 20px;

		}

		h4 {
			font-size: 16px;
			font-weight: bold;
			color: #010101;
			margin-bottom: 20px;
			margin-top: 20px;

			@include media-breakpoint-down(sm) {
				margin-top: 25px;
			}
		}

		i {
			font-size: 60px;
			flex: 0 0 100px;
			max-width: 100px;
			text-align: center;
			display: block;
			color: #C9C9C9;
		}

		span {
			font-size: 14px;
			color: #010101;

			@include media-breakpoint-down(sm) {
				font-size: 12px;
			}
		}
	}

	

	&__reviews {
		padding: 40px;

		@include media-breakpoint-down(sm) {
			padding: 30px 0 10px 0;
		}

		h3 {
			color: #000;
			font-weight: bold;
			font-size: 16px;
			text-transform: uppercase;
			margin-bottom: 30px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 0px;
			}
		}

		.getAllReviews {
			font-size: 14px;
			display: inline-flex;
			align-items: center;
			margin-top: 30px;
			border-width: 2px;

			span {
				color: #000;
				transition: color .3s;
			}

			i {
				font-size: 1.4em;
				margin-right: 6px;
			}

			&:hover {
				span {
					color: #fff;
				}
			}
		}

		.comment {
			&__footer {
				margin-top: 20px;
			}
			&__created {
				position: static;

				@include media-breakpoint-down(sm) {
					transform: none;
				}
			}
			&__status {
				font-size: 12px;
			}
		}

		&_empty {
			padding: 30px 0;
			text-align: center;
			max-width: 70%;
			margin-left: auto;
			margin-right: auto;

			@include media-breakpoint-down(sm) {
				padding: 15px 0;
				margin-top: 20px;
			}

			@include media-breakpoint-down(xs) {
				max-width: 90%;
			}
			
			.icon-write_noreviews {
				font-size: 50px;
				color: #C2C2C2;
			}

			&_msg {
				font-size: 16px;
				font-weight: 300;
				color: #757575;
				margin-top: 15px;

				@include media-breakpoint-down(sm) {
					font-size: 14px;
					margin-top: 10px;
				}
			}

			&_note {
				font-size: 14px;
				color: #757575;
				margin-top: 30px;

				 @include media-breakpoint-down(sm) {
				 	font-size: 12px;
				 	margin-top: 20px;
				 }
			}
		}
	}
}

.subscription__date {
	position: relative;
	max-width: 84px;

	@include media-breakpoint-down(sm) {
		margin: 10px 0;
		margin-left: auto;
		margin-right: auto;
	}

	.icon-calendar {
		font-size: 84px;
		color: #CACACA;
	}

	&_inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding-top: 15px;

		span {
			font-size: 26px;
			color: #ccc;
			font-weight: bold;
			display: flex;
			line-height: 1;
		}

		i {
			font-style: normal;
			font-size: 10px;
			text-transform: uppercase;
			color: #000;
			margin-top: 3px;
			line-height: 1.1em;
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			display: block;
		}

		&.active {
			span {
				color: #049c08;
			}
		}
	}
}



.menu_categories_mobile {
	margin: 0 0 -20px 0;
	padding: 0px 15px;
	white-space: nowrap;
  	overflow-x: auto;
  	-webkit-overflow-scrolling: touch;
  	-ms-overflow-style: -ms-autohiding-scrollbar;
  	background-color: #F0F0F0;
  	display: none;
  	list-style: none;

  	@include media-breakpoint-down(sm) {
  		display: block;
  	}

  	&::-webkit-scrollbar {
  		display: none;
  	}

  	li {
  		display: inline-block;
  		margin-right: 0px;

  		a {
  			padding: 15px 10px;
  			display: flex;
  			align-items: center;
  			font-size: 12px;
  			color: #000;
  			text-decoration: none;

  			&.active {
  				background-color: darken(#F0F0F0, 5%);
  			}

  			i {
  				font-size: 18px;
  				color: $blue;
  				position: relative;
  				bottom: 1px;
  				margin-right: 4px;
  			}
  		}
  	}
}
 

.payments {
	@include media-breakpoint-down(sm) {
		margin-left: -15px;
		margin-right: -15px; 
	}

	&__header {
		padding: 30px 40px 10px 40px;

		@include media-breakpoint-down(sm) {
			padding: 0 15px 20px 15px;
			margin-top: -10px;
		}

		h3 {
			font-size: 16px;
			color: #000;
			margin: 0;

			@include media-breakpoint-down(xs) {
				font-size: 14px;
			}
		}

		.btn {
			font-size: 14px;
			padding-top: 6px;
			padding-bottom: 6px;
			border-color: #C8C8C8;
			display: inline-flex;
			align-items: center;
			color: #000;
			font-weight: 500;

			@include media-breakpoint-down(xs) {
				font-size: 12px;
			}

			&:hover {
				color: #fff;
			}

			i {
				transform: rotate(90deg);
				font-size: 10px;
				position: relative;
				bottom: 1px;
				margin-right: 6px;
			}
		}
	}

	&__table {
		.table {
			margin: 0;

			@include media-breakpoint-down(xs) {
				font-size: 11px;
			}
		}
	}

	&__pagination {
		padding: 40px 40px;
	}
}

.oss_td {
	display: flex;
	align-items: center;

	img {
		width: 22px;
		height: 22px;
		object-fit: cover;
		margin-right: 8px;
		position: relative;
		bottom: 1px;
	}
}


.table-respons {
	@include media-breakpoint-down(sm) {
		thead {
			display: none;
		}

		tr {
			display: block;
			padding: 10px 0;
		}

		td {
			display: block;
			padding-left: 40%!important;
			padding-top: 9px;
			padding-bottom: 9px;
			position: relative;
			border-top: none;

			&:before {
				content: attr(data-label);
				width: 40%;
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				align-items: center;
				height: 100%;
				padding: 0 15px;
				font-weight: 500;
				color: #000;
			}
		}
	}
}



.unsubscribe, .carriers {
	@include media-breakpoint-down(sm) {
		margin-left: -15px;
		margin-right: -15px;
	}

	h3 {
		font-size: 16px;
		color: #000;
		margin: 0;
	}

	&__section {
		padding: 40px;
		border-top: 1px solid #D8D8D8;

		@include media-breakpoint-down(sm) {
			padding: 30px 15px;
		}

		p {
			font-size: 14px;
			font-weight: 300;
			margin-top: 30px;
			margin-bottom: 0;
			color: #000;

			@include media-breakpoint-down(sm) {
				margin-top: 20px;
				font-size: 12px;
			}
		}
	}

	&__result {
		margin-top: 40px;
		margin-bottom: 10px;

		@include media-breakpoint-down(sm) {
			font-size: 14px;
			margin-top: 30px;
		}

		@include media-breakpoint-down(xs) {
			font-size: 12px;
			margin-top: 20px;
			margin-bottom: 0;
		}

		i {
			font-size: 60px;

			@include media-breakpoint-down(sm) {
				font-size: 50px;
			}

			@include media-breakpoint-down(xs) {
				font-size: 40px;
			}
		}

		p {
			margin-bottom: 0px;
		}

		a {
			text-decoration: none;
			font-size: 14px;

			@include media-breakpoint-down(sm) {
				font-size: 12px;
			}
		}

		&_success {
			i {
				color: #009B0D;
			}
		}

		&_error {
			i {
				color: #FF2932;
			}
		}
	}

	&__actions {
		padding: 40px;

		@include media-breakpoint-down(sm) {
			padding: 0 15px 30px 15px;
		}

		&_note {
			margin-top: 30px;
			margin-bottom: 20px;

			@include media-breakpoint-down(sm) {
				font-size: 14px;
			}

			@include media-breakpoint-down(xs) {
				font-size: 12px;
				margin-top: 20px;
				margin-bottom: 15px;
			}
		}
	}

	&__oss {
		padding: 40px 40px 20px 40px;
		border-top: 1px solid #D8D8D8;
		
		@include media-breakpoint-down(sm) {
			padding: 30px 15px 10px 15px;
		}
	}

	&__contacts {
		padding: 40px;
		border-top: 1px solid #D8D8D8;

		@include media-breakpoint-down(sm) {
			padding: 30px 15px 0 15px;
		}
	}

	#country_code {
		text-align: center;
	}

	.form-group {
		margin-bottom: 0;

		@include media-breakpoint-down(xs) {
			margin-bottom: 15px;
		}
	}

	.fancy-select {
		@include media-breakpoint-down(xs) {
			margin-bottom: 15px;
		}
	}

	.form-control {
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 9px;
		padding-bottom: 9px;
		font-size: 14px;
		background-color: #fff;
		
	}

	.btn {
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		border-width: 2px;

		@include media-breakpoint-down(md) {
			font-size: 13px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 12px;
			padding-left: 5px;
			padding-right: 5px;
		}

		@include media-breakpoint-down(xs) {
			width: auto;
			margin-left: auto;
			margin-right: auto;
			padding-left: 25px;
			padding-right: 25px;
		}

		span {
			color: #000;
			transition: color .3s;
		}

		i {
			margin-right: 4px;
		}

		&:hover {
			span {
				color: #fff;
			}
		}
	}
}


.oss_list {
	font-size: 14px;
	color: #000;
	margin-top: 15px;

	@include media-breakpoint-down(xs) {
		font-size: 12px;
	}

	&__item {
		margin: 15px 0;

		@include media-breakpoint-down(xs) {
			margin: 10px 0;
		}

		&_title {
			margin-bottom: 15px;
			display: flex;
			align-items: center;
			font-weight: bold;

			img {
				width: 22px;
				height: 22px;
				border-radius: 50%;
				margin-right: 8px;

				@include media-breakpoint-down(xs) {
					width: 18px;
					height: 18px;
					margin-right: 6px;
				}
			}
		}

		&_note {
			font-size: 12px;
			font-weight: 300;
			color: #757575;
			margin-bottom: 10px;

			@include media-breakpoint-down(xs) {
				font-size: 11px;
			}
		}

		&_text {
			margin-bottom: 10px;
		}
	}
}




.vote-on-hover {
	position: absolute;
	top: -5px;
	left: 0;
	width: 100%;
	visibility: hidden;
	opacity: 0;
	transition: opacity .5s;

	&__title {
		font-weight: 500;
		color: #000;
		font-size: 14px;
		margin-bottom: 8px;
	}
}

.toggle-on-hover {
	position: relative;

	&:hover {
		.rate-on-hover {
			opacity: 0;
		}
		.vote-on-hover {
			visibility: visible;
			opacity: 1;
		}
	}
}

.dropdown-votes {
	.rate__value {
		cursor: pointer;

	}	

	.dropdown-menu {
		margin: 0;
		padding: 0;
		left: 50%!important;
		border: none;
		top: 30px!important;
		background-color: transparent;
		transform: translateX(-50%)!important;

		&:after {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 6px 6px 6px;
			border-color: transparent transparent #000 transparent;
			position: absolute;
			left: 50%;
			margin-left: -6px;
			bottom: 100%;
		}
	}

	.vote {
		&__item {
			background-color: #000000;
			border-left-color: #313131;
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
}








.textpage {
	&__inner {
		padding: 40px;
		border-bottom: 1px solid #D8D8D8;
		

		@include media-breakpoint-down(sm) {
			margin-left: -15px;
			margin-right: -15px;
			padding: 0 15px 20px 15px;
		}
	}

	&__contacts {
		padding: 40px;

		@include media-breakpoint-down(sm)  {
			padding-left: 0;
			padding-right: 0;
			padding-top: 20px;
			padding-bottom: 0px;
		}
	}

	h3 {
		color: #000;
		font-weight: bold;
		font-size: 16px;
		text-transform: uppercase;
		margin-bottom: 30px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 20px;
			font-weight: 500;
		}
	}

	p {
		font-size: 14px;

		@include media-breakpoint-down(sm) {
			font-size: 12px;
		}
	}

	ol {
		counter-reset: section;
		list-style-type: none;

		margin: 0;
		padding: 0;

		li:before {
			counter-increment: section; 
			content: counters(section,".") " ";
		}
	}

	.ol__top {

		margin-bottom: 50px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		> li {
			margin-top: 30px;

			@include media-breakpoint-down(sm) {
				margin-top: 20px;
			}

			&:before {
				content: counters(section,".") ". ";
				font-size: 18px;
				font-weight: bold;

				@include media-breakpoint-down(sm) {
					font-size: 16px;
				}
			}

			span {
				font-size: 18px;
				font-weight: bold;

				@include media-breakpoint-down(sm) {
					font-size: 16px;
				}
			}

			> ol {
				

				> li {
					margin-top: 20px;
					font-size: 14px;

					@include media-breakpoint-down(sm) {
						font-size: 12px;
						margin-top: 15px;
					}
				}
			}
		}
	}

	
}





.advantages {
	padding: 25px 15px 30px 15px;
	border-bottom: 1px solid #D8D8D8;

	@include media-breakpoint-down(sm) {
		margin-left: -15px;
		margin-right: -15px;
		border-top: 1px solid #D8D8D8;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	&_item {
		display: flex;
		align-items: center;
		justify-content: center;

		i {
			font-size: 34px;
			margin-right: 10px;

			@include media-breakpoint-down(sm) {
				font-size: 28px;
			}

			&.icon-weekly_updates {
				position: relative;
				bottom: 2px;
			}
		}

		span {
			display: block;
			line-height: 1.2;
			font-size: 12px;
			text-transform: uppercase;
			color: #000;

			@include media-breakpoint-down(sm) {
				font-size: 10px;
			}
		}
	}
}


.sidebar__menu {
	h3 {
		font-size: 16px;
	    color: #000;
	    margin: 9px 0;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 5px 0 0 0;

		li {
			a {
				font-size: 14px;
				color: #000;
				font-weight: 500;
				line-height: 1.4;
				display: block;
				margin-top: 25px;
				transition: color .3s;

				&.active, &:hover {
					color: $blue;
					text-decoration: none;
				}
			}
		}
	}
}