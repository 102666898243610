.modal-comments {
	textarea {
		padding: 0;
		font-size: 12px;
		border-bottom: none;
		
	}

	&__length_rewiev {
		font-size: 12px;
		font-weight: 300;
		color: #757575;
	}

	.form-group {
		margin: 0;
	}

	.modal-dialog {
		max-width: 780px;
	}

	.modal-footer {
		padding-bottom: 40px;

		@include media-breakpoint-down(xs) {
			padding-bottom: 30px;
		}

		.modal-title {
			margin-bottom: 10px;

			@include media-breakpoint-down(xs) {
				margin-bottom: 6px;
			}

			@media(max-width: 350px) {
				margin-bottom: 10px;
			}
		}
	}

	.vote {
		margin-right: 20px;

		@include media-breakpoint-down(xs) {
			margin-right: 15px;
		}
	}

	.rate_circle {
		margin-right: 20px;

		@include media-breakpoint-down(xs) {
			margin-right: 15px;
		}

		@media(max-width: 350px) {
			// display: none;
		}
	}

	.btn-outline-primary {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-top: 15px;
		font-size: 14px;
		border-width: 2px;

		@include media-breakpoint-down(xs) {
			margin-top: 10px;
			font-size: 12px;
		}

		@media(max-width: 350px) {
			margin-top: 15px;
		}

		span {
			color: #000;
			transition: color .3s;
		}

		i {
			font-size: 1.24em;
			margin-right: 6px;
		}

		&:hover {
			span {
				color: #fff;
			}
		}
	}
}

.rate_value {
	font-size: 48px;
	font-weight: 500;
	color: #C6C6C6;
	transition: color .3s;

	@include media-breakpoint-down(xs) {
		font-size: 40px;
	}

	@media(max-width: 350px) {
		// display: none;
	}
}

.vote {
	
	&__stars {
		display: flex;
		align-items: center;
		// flex-direction: row-reverse;
	}

	&__item {
		padding: 8px 12px;
		background-color: #F6F6F6;
		color: #A7A7A7!important;
		border-left: 1px solid #fff;
		cursor: pointer;
		transition: color .3s;

		@include media-breakpoint-down(xs) {
			padding: 6px 8px;
			font-size: 14px;
		}

		@media(max-width: 350px) {
			padding: 5px 6px;
			font-size: 12px;
		}

		&.active, &.selected {
			color: #EEA835!important;

			// & ~ * {
			// 	color: #EEA835;
			// }
		}

		&.selected {
			&.active {
				color: darken(#EEA835, 10%)!important;
			}
		}

		&:last-child {
			border-radius: 0 20px 20px 0;
			padding-right: 20px;

			@include media-breakpoint-down(xs) {
				padding-right: 15px;
			}

			@media(max-width: 350px) {
				padding-right: 10px;
			}
		}
		&:first-child {
			
			border-radius: 20px 0 0 20px;
			padding-left: 20px;
			border-left: none;

			@include media-breakpoint-down(xs) {
				padding-left: 15px;
			}

			@media(max-width: 350px) {
				padding-left: 10px;
			}
		}
	}
}


.vote_mobile {
	margin-top: 30px;

	&_text {
		font-size: 12px;
		margin: 0 10px;
	}
}