.payment_form {
	padding: 20px 40px 40px 40px;
	border-bottom: 1px solid #D8D8D8;

	@include media-breakpoint-down(sm) {
		padding: 20px 0 30px 0;
		border-bottom: none;
	}

	&__header {
		margin-bottom: 30px;

		h4 {
			margin: 0;
			font-size: 22px;
			font-weight: 300;
		}

		.btn {
			font-size: 14px;
			display: inline-flex;
			align-items: center;
			color: #000;

			@include media-breakpoint-down(sm) {
				font-size: 12px;
			}

			border-color: #C8C8C8;

			&:hover {
				color: #fff;
			}

			i {
				margin-right: 6px;
			}
		}
	}

	&__body {
		h4 {
			color: #000;
			font-weight: bold;
			font-size: 16px;
			text-transform: uppercase;
			margin-bottom: 20px;
		}

		.form-control {
			background-color: transparent!important;
			border-top-color: transparent!important;
		}

		.btn {
			font-size: 14px;
			margin-top: 10px;

			@include media-breakpoint-down(sm) {
				font-size: 12px;
				margin-top: 5px;
			}
		}

		.btn-outline-primary {
			color: #000;
			border-width: 2px;
			
			padding-left: 25px;
			padding-right: 25px;

			&:hover {
				color: #fff;
			}
		}

		div.fancy-select div.trigger {
			border-radius: 0;
			padding-top: 13px;
			padding-bottom: 12px;
			border-left: none;
			border-right: none;
			border-top: none;
			border-bottom-color: #ced4da;
			padding-left: 10px;
		}


		.table {
			margin-top: 10px;

			td {
				padding: 6px 0;
				border: none;



				&:last-child {
					text-align: right;
					font-size: 14px;
					color: #000;
				}

				&.green {
					color: #4ab178;
				}
			}

			tbody {
				tr:last-child {
					td {
						padding-bottom: 10px;
					}
				}
			}

			tfoot {
				margin-top: 10px;
				td {
					border-top: 1px dashed #000;
					padding-top: 13px;
					font-size: 14px;
					font-weight: bold;
				}
			}
		}
	}
}

.pr30 {
	padding-right: 30px;

	@include media-breakpoint-down(sm) {
		padding-right: 15px;
		margin-top: 20px;
	}
}

.card-js {
	.form-control {
		padding-right: 50px;
		font-size: 14px;


		@include media-breakpoint-down(sm) {
			font-size: 12px;
		}
	}

	.form-group {
		margin-bottom: 20px;
	}

	.card-number__col, .name-wrapper__col {
		width: 50%;

		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}

	.card-number__col {
		float: left;
		padding-right: 5px;

		@include media-breakpoint-down(sm) {
			padding-right: 0;
		}
	}

	.name-wrapper__col {
		float: right;
		padding-left: 15px;

		@include media-breakpoint-down(sm) {
			padding-left: 0;
		}
	}

	.cvc-wrapper {
		margin-left: 15px;
	}

	.form-group__icon {
		display: flex;
		justify-content: center;
		align-items: center;

		.icon-name {
			font-size: 22px;

			@include media-breakpoint-down(sm) {
				font-size: 20px;
			}
		}
	}

	.card-type-icon {
		width: 34px;
		height: 24px;

		&.visa {
			right: 1px;
			
			i {
				display: none;
			}

			background-image: url(../img/visa.svg);
			background-size: 28px auto;
			background-repeat: no-repeat;
			background-position: 0% 50%!important;
		}

		&.master-card {
			i {
				display: none;
			}

			background-image: url(../img/master_card.svg);
			background-size: 28px auto;
			background-repeat: no-repeat;
			background-position: 0% 50%!important;
		}

		&.american-express {
			i {
				display: none;
			}

			background-image: url(../img/american_express.svg);
			background-size: 28px auto;
			background-repeat: no-repeat;
			background-position: 0% 50%!important;
		}

		&.discover {
			i {
				display: none;
			}

			background-image: url(../img/discover_network.svg);
			background-size: 28px auto;
			background-repeat: no-repeat;
			background-position: 0% 50%!important;
		}

		&.diners {
			right: 3px;

			i {
				display: none;
			}

			background-image: url(../img/dinner_club.svg);
			background-size: 30px auto;
			background-repeat: no-repeat;
			background-position: 0% 50%!important;
		}

		&.jcb {
			right: 3px;

			i {
				display: none;
			}

			background-image: url(../img/jcb.svg);
			background-size: 32px auto;
			background-repeat: no-repeat;
			background-position: 0% 50%!important;
		}
	}
}