.game_previews {
	margin: 0 -5px;
}

.game_preview {
	border-radius: 6px;
	box-shadow: 0 0 10px 0px rgba(0,0,0,0.15);
	margin: 0 5px 25px;
	width: 152px;
	border: 1px solid transparent;
	transition: transform 0.3s, border-color .3s;
	background-color: #fff;
	overflow: hidden;
	transform: translateY(0px);
	outline: none;
	font-size: 12px;

	&:hover {
		transform: translateY(-10px);
		border-color: #D7D7D7;

		.game_preview-play {
			visibility: visible;
			opacity: 1;

			a {
				transform: translate(-50%, -50%);
			}
		}
	}

	&-top {
		padding: 10px;
		position: relative;
	}

	&-bottom {
		padding: 5px 10px 10px 10px;
	}

	&-image {
		display: block;
		width: 100%;
		height: auto;
		padding-bottom: 100%;
		border-radius: 6px;
		overflow: hidden;
		position: relative;
		margin: 0 auto;

		img {
		    position: absolute;
		    min-width: 100%;
		    min-height: 100%;
		    top: 50%;
		    left: 50%;
		    transform: translate(-50%, -50%);
		}
	}

	&-play {
		position: absolute;
		visibility: hidden;
		opacity: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-color: rgba(0,0,0,0.5);
		transition: opacity .3s;

		.btn {
			position: absolute;
			top: 50%;
			left: 50%;
		}

		i {
			font-size: 2em;
			position: absolute;
			top: 15px;
			left: 15px;
		    color: #BCBCBC;
		}
	}

	&-name {
		font-weight: 500;
		font-size: 16px;
		color: $black;
		display: block;
		margin-bottom: 3px;
		white-space: nowrap;
		position: relative;
		overflow: hidden;

		&:hover {
			text-decoration: none;
			color: #848383;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: -1px;
			width: 1px;
			height: 100%;
			box-shadow: 0 0 20px 10px rgba(255,255,255,1);
			z-index: 3;
		}
	}

	&-category {
		display: inline-block;
		vertical-align: top;
		color: $blue;
		font-weight: 500;
		margin-bottom: 10px;

		&:hover {
			text-decoration: none;
			color: #3a43b8;
		}
	}

	&-rating {
		.rate__value {
			font-size: 12px;
		}
	}

	.game_icon {
		display: none;
		font-size: 1.5em;
		color: #BCBCBC;
	}

	@include media-breakpoint-down(md) {
		&:hover {
			transform: translateY(0);
			border-color: transparent;
		}

		&-play {
			display: none;
		}

		.game_icon {
			display: block;
		}
	}

	@include media-breakpoint-down(sm) {
		&-name {
			font-size: 14px;
		}
	}
}