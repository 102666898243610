.top-games {
	margin-top: -170px;
	margin-bottom: 70px; 

	@include media-breakpoint-down(xs) {
		margin-bottom: 50px;
	}

	&__title {
		max-width: 840px;
		margin-left: auto;
		margin-right: auto;
		font-weight: bold;
		color: #ffcb34;
		font-size: 16px;
		text-transform: uppercase;
		margin-bottom: 5px;

		@include media-breakpoint-down(md) {
			max-width: 600px;
		}

		@include media-breakpoint-down(sm) {
			max-width: 60vw;
		}

		@include media-breakpoint-down(xs) {
			width: 75vw;
			max-width: 300px;
			font-size: 14px;
			margin-bottom: 2px;
		}
	}
}

.main_slider {
	

	&__hover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.7);
		border-radius: 10px 0 0 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		opacity: 0;
		visibility: hidden;
		transition: opacity .4s;



		@include media-breakpoint-down(xs) {
			display: none!important;
		}

		.btn {
			
			&-light {
				min-width: 240px;
				display: flex;
				align-items: center;
				justify-content: center;
				transform: translateY(-30px);
				transition: transform .4s;
				transition-delay: 0.02s;
			}

			&-outline-secondary {
				position: absolute;
				top: 100%;
				margin-top: 25px;
				left: 50%;
				transform: translateX(-50%);
				color: #fff;
				border-color: #6E6E6E;
				font-size: 14px;
				padding-left: 2rem;
				padding-right: 2rem;
				border-width: 2px;

				&:hover {
					background-color: transparent;
					border-color: lighten(#6E6E6E, 20%);
				}
			}

			i {
				margin-right: 8px;
				font-size: 20px;
			}
		}
	}



	&__category {
		position: absolute;
		left: 30px;
		top: 30px;
		color: #acacac;
		display: flex;
		font-size: 12px;
		font-weight: bold;
		justify-content: center;
		align-items: center;

		i {
			font-size: 2em;
			color: #BCBCBC;
			margin-right: 5px;
		}
	}

	&__buttons {
		position: relative;
	}

	&__slide {
		height: 420px;
		background-color: #fff;
		width: 840px;
		margin: 10px 15px;
		box-shadow: 0 0 20px rgba(0,0,0,0.25);
		border-radius: 10px;
		outline: none;
		overflow: hidden;
		position: relative;

		@include media-breakpoint-down(md) {
			height: 300px;
			width: 600px;
		}

		@include media-breakpoint-down(sm) {
			width: 60vw;
			height: auto;
			margin: 10px;
		}

		@include media-breakpoint-down(xs) {
			width: 76vw;
			max-width: 300px;
		}

		// &:after {
		// 	content:"";
		// 	display: block;
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	height: 100%;
		// 	background-color: rgba(0,0,0,0.7);
		// 	border-radius: 10px;
		// 	opacity: 0;
		// 	visibility: hidden;
		// 	transition: opacity .4s;
		// 	cursor: pointer;
		// }

		// &.slick-active {
		// 	&:after {
		// 		opacity: 0!important;
		// 		visibility: hidden!important;
		// 	}
		// }

		&.hover  {
			

			&:hover {
				.main_slider__hover {
					opacity: 1;
					visibility: visible;

					.btn {
						&-light {
							transform: translateY(0px);
						}
					}
				}

				.main_slider__img {
					img {
						// transform: translate(-50%, -50%) scale(1.05);
					}
				}
			}
		}

		
	}

	&:hover {
		.slick-arrow {
			visibility: visible;
			opacity: 1;
			transform: translateX(0);
		}
		.main_slider__slide {
			&:after {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	&__img {
		position: relative;
		height: 100%;
		overflow: hidden;

		> a {
			// pointer-events: none;
		}

		@include media-breakpoint-down(sm) {
			height: 260px;
		}

		@include media-breakpoint-down(xs) {
			height: auto;
			padding-bottom: 100%;
			border-radius: 6px 6px 0 0;

			a {
				pointer-events: all;
				display: block;
			}
		}

		img {
			position: absolute;
			min-width: 101%;
			min-height: 101%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: transform .4s;

			@include media-breakpoint-down(xs) {
				&:hover {
					transform: translate(-50%, -50%) scale(1.00)!important;
				}
				transition: none!important;
			}
		} 
	}

	&__info {
		padding: 30px 30px;
		height: 100%;
		display: flex;
		flex-direction: column;

		@include media-breakpoint-down(md) {
			padding: 20px;
		}

		@include media-breakpoint-down(sm) {
			padding-right: 50px;
		}

		@include media-breakpoint-down(xs) {
			padding-top: 15px;
			padding-bottom: 15px;
		}

		&_title {
			font-weight: bold;
			font-size: 30px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			color: #000;
			transition: color .3s;
			display: block;

			@include media-breakpoint-down(md) {
				font-size: 22px;
			}

			@include media-breakpoint-down(xs) {
				font-size: 18px;
			}


			&:hover {
				color: lighten(#000, 30%);
				text-decoration: none;
			}
		}

		&_stats {
			margin-top: 8px;

			a {
				margin-right: 20px;
				color: #4555ff;
				font-size: 12px;
				font-weight: 500;
				vertical-align: middle;
			}


			@include media-breakpoint-down(xs) {
				margin-top: 3px;
			}
		}

		&_description {
			font-size: 14px;
			margin-top: 25px;
			margin-bottom: 30px;
			overflow: hidden;
			height: 150px;

			@include media-breakpoint-down(md) {
				font-size: 12px;
				margin: 15px 0;
				height: 90px;
			}

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		&_screenshots {
			display: flex;
			margin: 0 -2px;
			overflow: hidden;
			position: relative;

			&:after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				width: 24px;
				@include gradient-x(rgba(255,255,255,0), rgba(255,255,255,1));
			}

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		&_mobile {
			display: none;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			color: #C0C0C0;
			font-size: 26px;

			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
	}

	.slick-arrow {
		background-color: transparent;
		position: absolute;
		width: 50px;
		height: calc(100% - 20px);
		z-index: 11;
		top: 10px;
		border: none;
		font-size: 28px;
		color: #FFB821;
		outline: none;
		cursor: pointer;
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s, color .3s, transform .3s, background-color .3s;
		background-color: rgba(0,0,0,0.6);
		// pointer-events: none;
		
		@include media-breakpoint-down(xs) {
			display: none!important;
		}

		i {
			transition: color .3s;
		}

		&:hover {
			color: lighten(#FFB821, 10%);
			background-color: rgba(0,0,0,0.7);
		}

		&__prev {
			left: 0;
			transform: translateX(-60px);
		}

		&__next {
			right: 0;
			transform: translateX(60px);
		}
	}

	.slick-dots {
		position: absolute;
		top: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		padding: 0;
		width: 100%;
		list-style: none;
		padding-top: 10px;

		li {
			margin: 0 6px;
			button {
				border: none;
				display: block;
				font-size: 0px;
				padding: 0;
				width: 8px;
				height: 8px;
				background-color: #E0E0E0;
				border-radius: 50%;
				text-indent: -9999px;
				box-sizing: border-box;
				transition: background-color .3s;
				cursor: pointer;
				outline: none;

				&:hover {
					background-color: darken(#E0E0E0, 10%);
				}
			}

			&.slick-active {
				button {
					background-color: #3C5DFE;
				}
			}
		}
	}
}

.screenshot {
	
	margin: 0 2px;
	position: relative;

	img {
		height: 79px;
		max-width: 150px;

		@include media-breakpoint-down(md) {
			height: 69px;
		}
	}
}