.comments {
	position: relative;
	z-index: 1;


	@include media-breakpoint-down(sm) {
		.row {
			.col-lg-4 {
				.comment {
					&:after {
						display: block!important;
						background-image: url(../img/review_gray.svg)!important;
						z-index: 1;
					}
				}
				&:nth-child(n+2) {
					.comment {
						background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
						background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
						background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );

						
					}

				}

				&:last-child {
					.comment {
						&:after {
							display: none!important;
						}
					}
				}
			}
		}
	}
	

	h3 {
		font-size: 16px;
		font-weight: bold;
		color: #000;
	}

	.btn-outline-primary {
		color: #000;
		font-size: 14px;
		font-weight: bold;
		border-width: 2px;

		@include media-breakpoint-down(sm) {
			margin-top: 30px;
		}

		i {
			font-size: 20px;
			margin-right: 6px;
			color: $blue;
			transition: color .3s;
		}

		&:hover {
			color: #fff;

			i {
				color: #fff;
			}
		}
	}

	@include media-breakpoint-down(xs) {
		.mb-5 {
			margin-bottom: 0px!important;
		}
	}
}

.comment {

	@include media-breakpoint-down(sm) {
		margin-bottom: 0px!important;
		margin-left: -15px;
		margin-right: -15px;
		padding: 25px 15px;
		border-bottom: 1px solid #DCDCDC;
		position: relative;

		&:after {
			content:"";
			display: block;
			width: 20px;
			height: 14px;
			position: absolute;
			left: 20px;
			top: 100%;
			background-image: url(../img/review_white.svg);
			background-size: contain;
		}
		
	}

	&:last-child {
		&:after {
			display: none;
		}
	}

	&__game {
		&_preview {
			img {
				border-radius: 3px;
				height: 36px;

				@include media-breakpoint-down(xs) {
					height: 30px;
				}
			}
		}

		&_title {
			font-weight: bold;
			font-size: 16px;
			color: #000;
			margin-left: 15px;
			text-decoration: none!important;
			transition: color .3s;

			@include media-breakpoint-down(xs) {
				font-size: 14px;
				margin-left: 10px;
			}

			&:hover {
				color: $blue;
			}
		}
	}

	&__body {
		background-color: #fff;
		border-radius: 8px;
		padding: 20px 25px;
		margin-top: 20px;
		box-shadow: 0 0 15px rgba(0,0,0,0.1);
		position: relative;
		z-index: 2;

		@include media-breakpoint-down(xs) {
			
		}

		@include media-breakpoint-down(sm) {
			padding: 0;
			background-color: transparent!important;
			box-shadow: none;

			&:after {
				display: none!important;
			}
		}

		&:after {
			content:""; 
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 30px 20px 0;
			border-color: transparent #fff transparent transparent;
			filter: drop-shadow(0px 4px 2px rgba(0,0,0,.1));
			position: absolute;
			top: 100%;
			left: 40px;

			@include media-breakpoint-down(xs) {
				border-width: 0 30px 20px 0;
			}
		}

		p {
			font-size: 14px;
			font-weight: 300;
			margin-bottom: 10px;
			color: #000;

			@include media-breakpoint-down(xs) {
				font-size: 12px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__author {
		margin-top: 25px;
		padding-left: 10px;

		@include media-breakpoint-down(sm) {
			position: relative;
			padding-left: 0;
		}

		@include media-breakpoint-down(xs) {
			margin-top: 15px;
		}

		&_avatar {
			margin-right: 10px;

			@include media-breakpoint-down(sm) {
				display: none;
			}

			img {
				border-radius: 50%;
				width: 40px;
				height: 40px;

				@include media-breakpoint-down(xs) {
					width: 36px;
					height: 36px;
				}
			}
		}

		&_name {
			font-size: 14px;
			font-weight: bold;
			color: #000;
			position: relative;
			min-width: 200px;

			@include media-breakpoint-down(sm) {
				position: static;
			}

			@include media-breakpoint-down(xs) {
				font-size: 12px;
			}
		}
	}

	&__created {
		position: absolute;
		top: 100%;
		left: 0;
		font-size: 11px;
		color: #757575;
		font-weight: 300;
		margin-top: 3px;

		@include media-breakpoint-down(sm) {
			top: 50%;
			left: auto;
			right: 5px;
			margin-top: 0;
			transform: translateY(-50%);
		}
	}
}