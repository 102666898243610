div.fancy-select {
	position: relative;
	font-size: 13px;
	color: #46565D;
}

div.fancy-select.disabled {
	opacity: 0.5;
}

div.fancy-select select:focus + div.trigger {
	// box-shadow: 0 0 0 2px #4B5468;
}

div.fancy-select select:focus + div.trigger.open {
	box-shadow: none;
}

div.fancy-select div.trigger {
	border-radius: 20px;
	cursor: pointer;
	padding: 9px 40px 9px 25px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	background: #fff;
	border: 1px solid #C8C8C8;
	color: #000;
	outline: none;
	width: 100%;
	font-weight: 400;
	font-size: 13px;
	display: flex;
	align-items: center;

	transition: all 240ms ease-out;
	-webkit-transition: all 240ms ease-out;
	-moz-transition: all 240ms ease-out;
	-ms-transition: all 240ms ease-out;
	-o-transition: all 240ms ease-out;

	img {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		object-fit: cover;
		object-position: 50% 50%;
		margin-right: 8px;
	}
}

div.fancy-select div.trigger:after {
	content: "\e900";
	font-family: 'icomoon' !important;
	display: flex;
	height: 100%;
	align-items: center;
	position: absolute;
	border-top-color: #4B5468;
	top: 1px;
	right: 16px;
	font-size: 12px;
}

div.fancy-select div.trigger.open, div.fancy-select div.trigger:hover {
	border-color: $blue!important;
	box-shadow: none;
}

div.fancy-select div.trigger.open:after {
	border-top-color: #7A8498;
}

div.fancy-select ul.options {
	list-style: none;
	margin: 0;
	position: absolute;
	padding: 0px 0;
	top: 40px;
	left: 0;
	visibility: hidden;
	opacity: 0;
	z-index: 50;
	max-height: 200px;
	overflow: auto;
	background: #fff;
	border-radius: 8px;
	// border-top: 1px solid #7DD8D2;
	border: 1px solid rgba(0, 0, 0, 0.15);
	min-width: 100%;

	transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
	-webkit-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
	-moz-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
	-ms-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
	-o-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
}

div.fancy-select ul.options.open {
	visibility: visible;
	top: 50px;
	opacity: 1;

	/* have to use a non-visibility transition to prevent this iOS issue (bug?): */
	/*http://stackoverflow.com/questions/10736478/css-animation-visibility-visible-works-on-chrome-and-safari-but-not-on-ios*/
	transition: opacity 300ms ease-out, top 300ms ease-out;
	-webkit-transition: opacity 300ms ease-out, top 300ms ease-out;
	-moz-transition: opacity 300ms ease-out, top 300ms ease-out;
	-ms-transition: opacity 300ms ease-out, top 300ms ease-out;
	-o-transition: opacity 300ms ease-out, top 300ms ease-out;
}

div.fancy-select ul.options.overflowing {
	top: auto;
	bottom: 40px;

	transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
	-webkit-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
	-moz-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
	-ms-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
	-o-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
}

div.fancy-select ul.options.overflowing.open {
	top: auto;
	bottom: 50px;

	transition: opacity 300ms ease-out, bottom 300ms ease-out;
	-webkit-transition: opacity 300ms ease-out, bottom 300ms ease-out;
	-moz-transition: opacity 300ms ease-out, bottom 300ms ease-out;
	-ms-transition: opacity 300ms ease-out, bottom 300ms ease-out;
	-o-transition: opacity 300ms ease-out, bottom 300ms ease-out;
}

div.fancy-select ul.options li {
	padding: 10px 20px;
	color: #000;
	cursor: pointer;
	white-space: nowrap;
	font-size: 12px;
	transition: all 150ms ease-out;
	-webkit-transition: all 150ms ease-out;
	-moz-transition: all 150ms ease-out;
	-ms-transition: all 150ms ease-out;
	-o-transition: all 150ms ease-out;
	display: flex;
	align-items: center;

	img {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		object-fit: cover;
		object-position: 50% 50%;
		margin-right: 10px;
	}
}

div.fancy-select ul.options li.selected {
	background: #f0f0f0;
}

div.fancy-select ul.options li.hover {
	background: #f0f0f0;
}