.footer {
	position: relative;
	padding-bottom: 50px;
	padding-top: 160px;
	background-image: url(../img/footer_desert.svg);
	background-repeat: no-repeat;
	background-position: 0% 0;
	background-size: 3400px auto;

	@include media-breakpoint-down(xs) {
		padding-bottom: 30px;
		background-size: 1100px auto;
	}

	.container-fluid {
		position: relative;
		z-index: 1;
	}

	.google_play_logo {
		@include media-breakpoint-down(xs) {
			img {
				height: 40px;
			}
		}
	}

	.contacts {
		margin: 0;
		justify-content: flex-end;

		@include media-breakpoint-down(sm) {
			flex-wrap: wrap;
		}

		&__item {
			margin-right: 0;
			margin-left: 40px;
			font-size: 14px;
			padding-left: 30px;

			.icon-serviceterms {
				left: 2px;
				top: 1px;
			}

			@include media-breakpoint-down(md) {
				font-size: 12px;
				margin-left: 25px;
			}

			@include media-breakpoint-down(sm) {
				width: 50%;
				margin: 10px 0;
				text-align: center;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;

				&:first-child {
					width: 100%;
				}

				i {
					position: static;
					margin-right: 8px;
				}
			}
		}
	}


	&__links {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 25px 0 0 0;
		padding: 0;

		@include media-breakpoint-down(xs) {
			margin-top: 20px;
		}

		li {
			margin-right: 35px;

			a {
				font-size: 14px;
				color: #3e3e3e;
				font-weight: bold;
				text-decoration: none;
				transition: color .3s;

				@include media-breakpoint-down(sm) {
					font-size: 12px;
				}

				@include media-breakpoint-down(xs) {
					font-size: 11px;
				}

				&:hover {
					color: #f4ba11;
				}
			}
		}
	}

	&__text, &__seo {
		font-size: 11px;
		color: #757575;
		margin-top: 25px;

		@include media-breakpoint-down(xs) {
			margin-top: 20px;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			color: inherit;
			text-decoration: underline;
		}
	}
}

.logo__footer {
	img {
		height: 30px;
	} 
}

.socials {
	margin-right: 10px;
	display: inline-flex;
	align-items: center;
	
	a {
		color: #373737;
		margin-right: 30px;
		font-size: 26px;
		text-decoration: none;
		transition: color .3s;

		@include media-breakpoint-down(xs) {
			margin-right: 20px;
			font-size: 22px;
		}

		&:hover {
			color: #f4ba11;
		}
	}
}

.monstro {
	position: absolute;
	left: 300px;
	top: -100px;

	img {
		width: 180px;
	}

	&__mobile {
		display: none;

		img {
			width: 130px;
		}

		@include media-breakpoint-down(xs) {
			display: block;
			position: absolute;
			top: 30px;
			left: 25%;
			z-index: 3;
		}
	}
}

.copyright {
	font-size: 12px;
	font-weight: 300;
	color: #9e9e9e;

	@include media-breakpoint-down(md) {
		text-align: center;
		margin-top: 20px;
	}
}