.header {
	padding-top: 30px;
	height: 100%;


	&__compact {
		background-color: #313561;
		padding: 30px 0;
		background-image: none;

		@include media-breakpoint-down(md) {
			padding: 0;
		}

		.mb-2 {
			margin-bottom: 0!important;
		}
	}

	.google_play_logo {
		margin-top: 60px;
	}

}

.logo {
	position: relative;
	bottom: 5px;

	img {
		height: 28px;
		display: block;
	}
}

.sun {
	width: 120px;
	position: absolute;
	top: -15px;
	left: 40px;
	opacity: 0;

	// transition: transform .3s;

	&__face {
		position: absolute;
		width: 120px;
		top: 0;
		z-index: 2;
		display: block;

		img {
			display: block;
			height: 133px;
			width: 120px;
			transform: translateZ(0);
		}
	}



	&__shadow {
		&_1 {
			position: absolute;
			width: 124px;
			pointer-events: none;
			left: -2px;
			top: 21px;
			z-index: 2;

			svg {
				width: 100%;
			}
		}

		&_2 {
			position: absolute;
			width: 126px;
			pointer-events: none;
			left: -2px;
			top: 16px;
			z-index: 1;
			opacity: .15;

			svg {
				width: 100%;
			}
		}

		&_3 {
			position: absolute;
			width: 128px;
			pointer-events: none;
			left: -2px;
			top: 16px;
			z-index: 1;
			opacity: .15;

			svg {
				width: 100%;
			}
		}
	}
}

.categories {
	margin-bottom: 0px;
	padding-left: 20px;

	> li {
		display: inline-block;
		margin-right: 40px;
		position: relative;

		&.active {
			.reset_filter {
				display: block;
				color: #fff;
			}

			> a {
				color: #f4ba11;
			}
		}

		&.dropdown {
			display: none;

			&:hover {
				.dropdown-menu {
					display: block;
				}

				> a {
					color: #000;
					background-color: #fff;
					position: relative;
					z-index: 10000;
				}
			}
		}

		> a {
			color: #fff;
			font-weight: bold;
			font-size: 16px;
			display: flex;
			transition: color .3s, background-color .3s;

			@media(max-width: 1200px) {
				font-size: 14px;
			}

			&.reset_filter {
				font-size: 8px;
				position: absolute;
				left: 100%;
				margin-left: 3px;
				top: -7px;
				display: none;

				&:hover {
					color: darken(#fff, 10%);
				}
			}




			&:hover {
				color: #f4ba11;
				text-decoration: none;
				outline: none;
			}

			span {
			}

			i {
				font-size: 1.6em;
				margin-right: 8px;

				&.icon-onlinegames {
					position: relative;
					bottom: 3px;
				}

				&.icon-video {
					position: relative;
					bottom: 1px;
					font-size: 1.5em;
					margin-right: 9px;
				}
			}
		}
	}
}

.menu {
	margin-bottom: 0;
	padding-left: 20px;

	li {
		display: inline-block;
		margin-right: 25px;
		margin-top: 8px;
		margin-bottom: 8px;

		a {
			color: #c3d1ff;
			font-weight: 300;
			font-size: 14px;
			transition: color .3s;


			&:hover {
				color: #fff;
				text-decoration: none;
				outline: none;
			}
		}
	}
}

.controls {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.control {
	&__link {
		color: #fff;
		margin-left: 25px;
		display: inline-block;
		font-weight: 400;
		font-size: 14px;
		transition: color .3s;


		&:hover {
			color: #f4ba11;
			text-decoration: none;
			outline: none;
		}
	}

	&__button {
		margin-left: 25px;

		&:hover {
			background-color: #fff;
			border-color: #fff;
		}
	}

	&__profile {
		display: inline-flex;
		align-items: center;
		color: #fff;
		font-size: 14px;
		height: 39.44px;
		transition: color .3s;

		&_avatar {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 32px;
			background-color: #565B89;
			border-radius: 50%;
			width: 50px;
			height: 50px;
			margin-left: 10px;
			color: #fff!important;
			border: 2px solid #565B89;
			transition: border-color .3s;

			img {
				display: block;
				border-radius: 50%;
				max-width: 100%;
			}
		}

		&:hover {
			color: #f4ba11;
			text-decoration: none;
		}
		&:hover & {
			&_avatar {
				border: 2px solid #f4ba11;
			}
		}
	}
}

.search {
	position: relative;

	button {
		background-color: transparent;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		border: none;
		outline: none;
		cursor: pointer;
		right: 0;
		color: #fff;
		transition: color .3s;

		&:hover {
			color: #f4ba11;
		}
	}

	.form-group {
		margin-bottom: 0;
	}

	.form-control {
		background-color: transparent;
		border-radius: 0;
		border-top: none;
		border-left: none;
		border-right: none;
		padding-left: 5px;
		font-size: 12px;
		border-bottom-color: #587DDF;
		line-height: 2.2;
		color: #fff;
		padding-right: 40px;

		&::-webkit-input-placeholder {
			color: #b0b3db;
		}
		&::-moz-placeholder {
			color: #b0b3db;
		}
		&:-ms-input-placeholder {
			color: #b0b3db;
		}
		&:-moz-placeholder {
			color: #b0b3db;
		}

		&:focus {
			box-shadow: none;
			border-bottom-color: #fff;
		}
	}
}



body.fixed__nav {
	.content {
		margin-top: 100px;

		@include media-breakpoint-down(md) {
			margin-top: 0!important;
		}
	}


	.header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 60px;
		padding: 0;
		background-color: #313561;
		display: flex;
		align-items: stretch;
		background-image: none;
		z-index: 9999;
		animation-duration: 1s;
		animation-name: header;
	}

	.list_lang {
		border-radius: 0;
	}

	.hidden_fixed_nav {
		display: none;
	}


	.control {
		&__button {
			height: 60px;
			border-radius: 0;
			font-size: 12px;
			box-shadow: none!important;
		}

		&__link {
			font-size: 12px;
		}

		&__profile {
			font-size: 12px;

			&_avatar {
				width: 38px;
				height: 38px;
				font-size: 26px;
			}
		}
	}

	.categories {
		padding-left: 0;

		> li {
			height: 60px;
			display: inline-flex;
			align-items: center;
			margin-right: 0;
			margin-right: 5px;

			&.dropdown {
				display: inline-block;
			}

			> a {
				font-size: 12px;
				height: 100%;
				padding: 0 20px;
				align-items: center;

				@media(max-width: 1200px) {
					font-size: 11px;
					padding: 0 13px;

					i {
						margin-right: 6px;
					}
				}

				i.icon-androidgames {
					bottom: 1px;
					position: relative;
				}

				i.icon-categories {
					position: relative;
					bottom: 1px;
				}

				&.reset_filter {
					right: -2px;
					height: auto;
					left: auto;
					padding: 0;
					top: 14px;
					font-size: 7px;

					@media(max-width: 1200px) {
						right: -8px;
					}
				}
			}


			&.show {
				> a {
					color: #000;
					background-color: #fff;
					position: relative;
					z-index: 10000;

				}
			}
		}
	}

	.mb-2 {
		margin-bottom: 0!important;
	}
}


.categories-dropdown {

	position: fixed!important;
	top: 60px!important;
	margin: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	border: none;
	border-radius: 0;
	border-bottom: 1px solid #D8D8D8;
	transform: none!important;
	box-shadow: 0 0 20px rgba(0,0,0,0.2);
	padding: 25px 0;

	&__list {
		list-style: none;
		margin: 0;
		padding: 0 0 0 10px;
		align-items: center;
		flex-wrap: wrap;
		display: flex;

		li {
			width: 33%;
			margin: 0px 0;

			a {
				font-size: 14px;
				color: #000;
				font-weight: 500;
				display: inline-flex;
				align-items: center;
				transition: color .3s;
				padding: 10px;
				transition: background-color .3s;

				&.active, &:hover {
					background-color: darken(#fff, 5%);
				}

				&:hover {
					text-decoration: none;
				}

				i {
					font-size: 1.8em;
					margin-right: 10px;
					color: $blue;
				}
			}
		}
	}
}

.popular_actions {
	height: 100%;
	border-left: 1px solid #D8D8D8;
	padding-left: 30px;
	padding-top: 10px;

	h3 {
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		color: #000;
		margin-bottom: 30px;
	}

	.sidebar__games_wrap {
		display: flex;
		margin: 0;
		padding: 0;
	}

	.sidebar__game {
		margin: 0;
		margin-right: 25px;
	}
}


@keyframes header {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0%);
	}
}

.list_lang {
	margin-top: 19px;
	left: 15px!important;
	border-radius: 10px;
	// min-width: 170px;

	&:after {
		content:"";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 10px 10px 10px;
		border-color: transparent transparent #fff transparent;
		display: block;
		position: absolute;
		right: 10px;
		bottom: 100%;
	}
	li {


		a {
			padding: 7px 20px;
			display: flex;
			align-items: center;
			font-size: 12px;
			color: #000;
			background-color: #fff;
			transition: background-color .3s;


			&.active {
				background-color: darken(#fff, 6%);
			}

			&:hover {
				text-decoration: none;
				background-color: darken(#fff, 6%);
			}

			img {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				object-fit: cover;
				object-position: 50% 50%;
				margin-right: 8px;
			}

			span {
				white-space: nowrap;
				padding-right: 20px;
			}
		}
	}

	&.mm-listview {
		margin-top: 0px!important;
		padding-bottom: 20px;
		min-height: 100%;
		border-radius: 0;

		li {
			a {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}
}


.dropdown__lang {


	&.show {
		.control__link {
			color: #f4ba11;
		}
	}
}


.dropdown__profile {


	.dropdown-menu {
		position: relative;
		margin-top: 18px;
		left: 0px!important;
		border-radius: 10px;

		&:after {
			content:"";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 10px 10px;
			border-color: transparent transparent #fff transparent;
			display: block;
			position: absolute;
			right: 12px;
			bottom: 100%;
		}

		li {
			a {
				padding: 7px 20px;
				display: flex;
				align-items: center;
				font-size: 12px;
				color: #000;
				background-color: #fff;
				transition: background-color .3s;

				&.active {
					background-color: darken(#fff, 6%);
				}

				&:hover {
					text-decoration: none;
					background-color: darken(#fff, 6%);
				}

				i {
					width: 24px;
					font-size: 16px;
					display: inline-block;
					text-align: center;
					margin-right: 10px;

					&.icon-user_login {
						font-size: 22px;
						color: $blue;
						position: relative;
						bottom: 2px;
					}

					&.icon-logout {
						position: relative;
						left: 1px;
						color: $red;
					}
				}
				span {
					white-space: nowrap;
					padding-right: 5px;
				}
			}
		}
	}

	&.show {
		.control__profile {
			color: #f4ba11;

			&_avatar {
				border: 2px solid #f4ba11;
			}
		}
	}
}
.fixed__nav {
	.dropdown__profile {
		.dropdown-menu {
			margin-top: 10px;

			&:after {
				right: 10px;
			}
		}
	}
	.dropdown-menu {
		&:after {
			border-width: 0 8px 8px 8px;
			right: 13px;
		}
	}
}