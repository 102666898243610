.tariff-plans {
	display: flex;

	@include media-breakpoint-down(md) {
		flex-wrap: wrap;
	}
}

.tariff-plan {
	text-align: center;
	
	margin-top: 15px;
	border-bottom: 1px solid #D7D7D7;
	margin-bottom: 10px;

	@include media-breakpoint-up(sm) {
		width: 32%;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
		display: none;

		&.active {
			display: block;
		}
	}

	@include media-breakpoint-down(sm) {
		border-bottom: none;
	}

	

	&__body {
		padding: 30px 15px 35px 15px;

		@include media-breakpoint-down(xs) {
			padding-top: 25px;
			padding-bottom: 25px;		
		}
	}

	&-centered {
		@include media-breakpoint-up(md) {
			width: 36%;
			border: 3px solid $blue;
			box-shadow: 0 0 10px rgba(0,0,0,0.3);
			margin-top: 0;
			margin-bottom: 0;
		}
		
	}

	&-centered & {
		&__body {
			@include media-breakpoint-up(sm) {
				padding-top: 42px;
				padding-bottom: 47px;
			}
			
		}
	}


	&__header {
		padding: 15px 15px;
		border-bottom: 1px solid #D8D8D8;

		span {
			font-size: 22px;
			font-weight: 300;
			position: relative;

			&:after {
				content: attr(data-after);
				position: absolute;
				left: 100%;
				margin-left: 6px;
				top: 0;
				font-size: 9px;
				text-transform: uppercase;
				color: #60ad1c;
				font-weight: bold;
			}
		}
	}
	

	&__price {
		span {
			font-size: 46px;
			color: #010101;
			display: inline-flex;
			line-height: .8;
			position: relative;

			&:before {
				content: attr(data-before);
				position: absolute;
				top: 0;
				right: 100%;
				font-size: 22px;
				color: #000;
				font-weight: 300;
				margin-right: 7px;
			}

			&:after {
				content: attr(data-after);
				position: absolute;
				left: 100%;
				top: 0;
				font-size: 14px;
				color: #7a7a7a;
				font-weight: 300;
				margin-left: 7px;
				min-width: 60px;
				text-align: left;
			}
		}
	}

	&__discount {
		font-size: 12px;
		color: #7a7a7a;
		font-weight: 300;
		margin-top: 5px;
		margin-bottom: 15px;
		height: 18px;
	}
	

	&__payment {
		display: inline-block;
		min-width: 175px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;

		label {
			font-size: 14px;
			color: #000;
		}

		.fancy-select {
			i {
				font-size: 18px;
				margin-right: 5px;

				&.icon-mobile {
					position: relative;
					bottom: 0px;
					margin-right: 4px;
				}
			}
		}

		.btn {
			width: 100%;
			font-size: 14px;
			border-width: 2px;

			@include media-breakpoint-down(sm) {
				background-color: $blue;
				border-color: $blue;
				color: #fff!important;
			}
		}

		.btn-outline-primary {
			color: #000;


			&:hover {
				color: #fff;
			}
		}
	}
}

.toggle-plans {

	.btn-group {
		width: 100%;
		justify-content: center;
	}
	.btn {
		font-size: 14px;
		font-weight: 400;
		box-shadow: none;
		border-color: #C8C8C8;
		flex: 0 0 33%;
		max-width: 33%;
		color: #000;

		&.active {
			background-color: $blue!important;
			border-color: $blue!important;
			color: #fff;
		}
	}
}