.player {
	width: 100%;
	
}
video {
	max-width: 100%;
	opacity: 0;
}

.plyr {
	video {
		opacity: 1;
	}
}

.video_title {
	font-size: 32px;
	font-weight: bold;
	color: #000;
	line-height: 1;
	
	@media(max-width: 767px) {
		font-size: 22px;
	}
}

.mb15 {
	margin-bottom: 15px;
}

.video {
	&__views {
		color: #B2B2B2;
		font-size: 14px;

		@media(max-width: 767px) {
			font-size: 12px;
		}

		i {
			margin-right: 6px;
			font-size: 12px;

			@media(max-width: 767px) {
				font-size: 10px;
			}
		}
	}

	&__preview {
		box-shadow: 0 0 12px rgba(0,0,0,0.3);
		border-radius: 0 0 3px 3px;

		&_image {
			display: block;
			position: relative;

			img {
				border-radius: 3px 3px 0 0;
				display: block;
			}

			.icon-video {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 32px;
				color: #fff;
				transition: color .3s;
			}

			&:hover {
				.icon-video {
					color: #ffc107;
				}
			}
		}

		&_info {
			background-color: #fff;
			padding: 10px 10px;
			border-radius: 0 0 3px 3px;


			&__title {
				display: block;
				font-size: 12px;
				font-weight: bold;
				color: #000;
				line-height: 1;
				margin-bottom: 4px;
				

				&:hover {
					color: lighten(#000, 20%);
					text-decoration: none;
				}
			}

			&__category {
				font-size: 11px;
				color: #4e5dff;
				display: block;
				line-height: 1;
				text-decoration: none;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

.sidebar__games_wrap {
	.video__preview {
		margin-bottom: 20px;
	}
}

.promo_slider_video .slick-list {
    padding: 10px 10px 10px 10px !important;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;

    @media(max-width: 991px) {
    	overflow: visible!important;
    }
}

.promo_slider_video {
	.video__preview {
		margin: 6px;

		@media(max-width: 991px) {
			max-width: 240px;
		}
	}


	.slick-arrow {
		display: block;
		position: absolute;
		width: 50px;
		height: 110%;
		background-color: $white;
		font-size: 24px;
		border: none;
		padding: 0;
		margin: 0;
		text-align: center;
		cursor: pointer;
		outline: none;
		color: #ccc;
		transition: all 0.2s linear 0s;
		top: -10px;
		z-index: 100;

		&:hover {
			color: $black;
		}
	}

	.slick-next {
		left: 100%;
	}

	.slick-prev {
		right: 100%;
		background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
	}
}

.page .promo {
	@media(max-width: 767px) {
		margin-top: 10px!important;
	}
}

.video_previews {
	margin-bottom: 15px;

	.row {
		margin-left: -10px;
		margin-right: -10px;
	}
	.col-3 {
		padding-left: 10px;
		padding-right: 10px;
	}
	.video__preview {
		margin-bottom: 20px;

		&_info {
			padding-left: 15px;
			padding-right: 15px;
			&__title {
				font-size: 14px;
			}
		}
	}


}