.games_grid {
	padding-top: 10px;
	position: relative;
	z-index: 10;
	
	h3 {
		font-size: 16px;
		margin-bottom: 0;
		font-weight: bold;
	}

	.breadcrumb {
		margin-bottom: 0;
	}

	.search {
		width: 100%;

		.form-control {
			border-bottom-color: #b6b6b6;
			color: $black;
			font-size: 14px;

			&::-webkit-input-placeholder { 
			  color: #b6b6b6;
			  font-size: 12px;
			}
			&::-moz-placeholder { 
			  color: #b6b6b6;
			  font-size: 12px;
			}
			&:-ms-input-placeholder { 
			  color: #b6b6b6;
			  font-size: 12px;
			}
			&:-moz-placeholder {
			  color: #b6b6b6;
			  font-size: 12px;
			}

			&:focus {
				box-shadow: none;
				border-bottom-color: $blue;
			}

			&:hover {
				box-shadow: none;
				border-bottom-color: $blue;
			}
		}

		button {
			color: $black;

			&:hover {
				color: $blue;
			}
		}
	}

	.game_preview {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: 200px;
	}

	.col {
		padding: 0 5px;
		width: 16.66%;
		flex: 0 0 16.66%;
	}

	@include media-breakpoint-down(lg) {

		.col {
			max-width: 20%;
			flex: 0 0 20%;
		}
	}

	@media (max-width: 870px) { 

		.col {
			max-width: 25%;
			flex: 0 0 25%;
		}
	}

	@include media-breakpoint-down(sm) {

		.col {
			max-width: 33.33%;
			flex: 0 0 33.33%;
		}

		h3 {
		}
	}

	@include media-breakpoint-down(xs) {

		.col {
			max-width: 50%;
			flex: 0 0 50%;
		}
	}
}

.pagination {
	margin-right: 20px;
	margin-bottom: 0;
	background-color: #f0f0f0;
	border-radius: 100px;

	&-nav {
		.btn {
			color: $black;
			font-size: 14px;
			border-width: 2px;

			&:hover {
				color: $white;
			}
		}
	}

	.page-item {
		border-radius: 100px;
		&.active {
			.page-link {
				background-color: $white;
				border-color: #c8c8c8;
				color: $black;
			}
		}

		&:first-child {
			.page-link {
				border-radius: 100px;
			}
		}

		&:last-child {
			.page-link {
				border-radius: 100px;
			}
		}
	}

	.page-link {
		background-color: transparent;
		border-color: transparent;
		border-radius: 100px;
		min-width: 38px;
		text-align: center;
		color: $black;
		box-shadow: none !important;

		&:hover {
			color: #928f8f;
		}
	}
}

.pagination-nav .btn + .pagination {
	margin-left: 20px;
}

.show-more {
	color: $black;
	i {
		font-size: 25px;
		color: $blue;
	}

	&:hover {
		color: $white;

		i {
			color: $white;
		}
	}
}

.animations-more {

	i {
		animation-name: moreRotate;
		animation-delay: 0s;
		animation-duration: .5s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	span {
		animation-name: moreMig;
		animation-delay: 0s;
		animation-duration: 1s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
}

@keyframes moreRotate {
    0%   {transform: rotate(0);}
    100% {transform: rotate(360deg);}
}
@keyframes moreMig {
    0%   {}
    50% {color: #aaa;}
    100% {}
}