.modal_subscribe_error {

	.modal-dialog {
		max-width: 700px;

		@include media-breakpoint-down(sm) {
			margin: 61px 0 0px 0;
			align-items: flex-start;
		}
	}

	.modal-content {
		@include media-breakpoint-down(sm) {
			border: none;
			border-radius: 0;
		}
	}

	.modal-header {
		background-color: #313562;
		border-radius: 8px 8px 0 0;
		position: relative;

		@include media-breakpoint-down(sm) {
			border-radius: 0;
			background-color: #fff;
			border-bottom: none;
			padding: 20px;
		}
	}

	.modal-body {
		padding-top: 50px;
		padding-bottom: 30px;
		font-size: 14px;

		@include media-breakpoint-down(sm)  {
			padding-top: 10px;
			padding-bottom: 20px;
			text-align: center;
		}
	}

	.advantages {
		margin-left: 0;
		margin-right: 0;
	}



	.close {
		color: #fff;

		@include media-breakpoint-down(sm) {
			color: #000;
		}

		@include media-breakpoint-down(xs) {
			font-size: 16px;
		}
	}

	.modal-title {
		color: #fff;
		font-weight: 500;

		@include media-breakpoint-down(sm) {
			color: #000;
			font-weight: bold;
		}

		@include media-breakpoint-down(xs) {
			font-size: 14px;
		}
	}

	&__img {
		position: absolute;
		left: 50%;
		top: -60px;
		transform: translateX(-50%);

		@include media-breakpoint-down(sm) {
			display: none;
		}

		&_2 {
			display: none;
		}

		img {
			width: 150px;


		}
	}

	&__text {
		padding-bottom: 10px;

		@include media-breakpoint-down(xs) {
			font-size: 13px;

			p {
				margin-bottom: 10px;
			}
		}
	}

	.btn-outline-primary {
		font-size: 14px;
		color: #000;
		border-width: 2px;
		min-width: 100%;
		margin-bottom: 1rem;

		&:hover {
			color: #fff;
		}

	}

	.btn-outline-secondary {
		font-size: 14px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-color: #C8C8C8;
		color: #000;

		&:hover {
			color: #fff;
		}

		i {
			margin-right: 6px;
		}
	}

	div.fancy-select div.trigger {
		background-color: transparent;
	}

}