.modal-trial {

	.modal-dialog {
		max-width: 760px;

		@include media-breakpoint-down(sm) {
			margin: 61px 0 0px 0;
			align-items: flex-start;
		}
	}

	.modal-content {
		@include media-breakpoint-down(sm) {
			border: none;
			border-radius: 0;
		}
	}

	.modal-header {
		background-color: #313562;
		border-radius: 8px 8px 0 0;
		position: relative;

		@include media-breakpoint-down(sm) {
			border-radius: 0;
			background-color: #fff;
			border-bottom: none;
			padding: 20px;
		}
	}

	.modal-body {
		padding-top: 40px;
		padding-bottom: 10px;
		font-size: 14px;

		@include media-breakpoint-down(sm)  {
			padding-top: 10px;
			padding-bottom: 10px;
			text-align: center;
		}
	}

	.advantages {
		margin-left: 0;
		margin-right: 0;
	}



	.close {
		color: #fff;

		@include media-breakpoint-down(sm) {
			color: #000;
		}

		@include media-breakpoint-down(xs) {
			font-size: 16px;
		}
	}

	.modal-title {
		color: #fff;
		font-weight: 500;

		@include media-breakpoint-down(sm) {
			color: #000;
			font-weight: bold;
		}

		@include media-breakpoint-down(xs) {
			font-size: 14px;
		}
	}

	&__img {
		position: absolute;
		left: 50%;
		top: -60px;
		transform: translateX(-50%);

		@include media-breakpoint-down(sm) {
			display: none;
		}

		&_2 {
			display: none;
		}

		img {
			width: 150px;


		}
	}

	&__text {
		padding-bottom: 10px;

		@include media-breakpoint-down(xs) {
			font-size: 13px;

			p {
				margin-bottom: 10px;
			}
		}
	}

	.btn-primary {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;

		@include media-breakpoint-down(xs) {
			font-size: 12px;
		}

		i {
			font-size: 1.3em;
			margin-right: 8px;
		}
	}
	
	.advantages {
		border-bottom: none;
	}

	.payment_form {
		@include media-breakpoint-up(md) {
			@include gradient-y(#FEFEFE, #F1F1F1);
			padding-top: 30px;
			border-radius: 10px;
		}

		@include media-breakpoint-down(sm) {
			padding-top: 0;
		}
	}

	div.fancy-select div.trigger {
		background-color: transparent;
	}

	.tariff-plan {
		@include media-breakpoint-up(md) {
			@include gradient-y(#FEFEFE, #F1F1F1);
		}
		

		&:first-child {
			border-radius: 10px 0 0 10px;
		}

		&:last-child {
			border-radius: 0 10px 10px 0;
		}

		&__payment {
			@include media-breakpoint-up(md) {
				.btn {
					background-color: #fff;

					&:hover {
						background-color: $blue;
					}
				}
			}
			
		}

		&-centered {

			@include media-breakpoint-up(md) {
				background: #292B52;
				border: none;

				.tariff-plan {
					&__header {
						background-color: #313461;
						color: #fff;
						border-bottom: 1px solid #484C70;

						span {
							&:after {
								color: #b0b5e3;
							}
						}
					}

					&__price {
						span {
							color: #fff;

							&:before {
								color: #fff;
							}

							&:after {
								color: #696faa;
							}
						}
					}

					&__discount {
						color: #a4a5d3;
					}

					&__payment {
						label {
							color: #fff;
						}
						div.fancy-select div.trigger {
							background-color: transparent;
							color: #fff;

							&:hover, &.open {
								border-color:  darken(#fff, 5%);
							}
						}

						.btn {
							background-color: #fff;
							border-color: #fff;
							color: #000;

							&:hover {
								background-color: darken(#fff, 5%);
							}
						}
					}
				}
			}


			
		}

	}
}

.modal-tariff {
	padding: 0 20px;

	@include media-breakpoint-up(md) {
		margin-left: -40px;
		margin-right: -40px;
		padding: 0;
	}
	
}

.header__trial_logo {
    visibility: hidden;
    position: absolute;
    top: -18px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 90px;
    opacity: 0;
    -webkit-transition: opacity 2s;
    -o-transition: opacity 2s;
    transition: opacity 2s;
	
	@media(max-width: 340px) {
		width: 70px;
	}
}

.trialModalOpen {
	@include media-breakpoint-down(sm) {
		.header__mobile_logo {
			display: none;
		}

		.header__compact {
		    position: fixed;
		    top: 0;
		    left: 0;
		    width: 100%;
		    z-index: 1051;
		    height: 61px;
		}
		

		.header__trial_logo {
			visibility: visible;
			opacity: 1;
		}

		.header__mobile_sticky {
			transform: translateY(100%);
		}

		.page {
		    padding-top: 61px;
		}
	}
	
}

.fade-up {
	transition: transform .3s, opacity .3s;
	transform: translateY(-50px);
	opacity: 0;

	&.show {
		transform: translateY(0);
		opacity: 1;
	}
}

.modal-step-2 {
	.modal-trial__img_1 {
		display: none;
	}

	.modal-trial__img_2 {
		display: block;
	}
}