@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?kca0uv');
  src:  url('../fonts/icomoon.eot?kca0uv#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?kca0uv') format('truetype'),
  url('../fonts/icomoon.woff?kca0uv') format('woff'),
  url('../fonts/icomoon.svg?kca0uv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-video:before {
  content: "\e93f";
}
.icon-toast-error:before {
  content: "\e93d";
}
.icon-toast-success:before {
  content: "\e93e";
}
.icon-serviceterms:before {
  content: "\e93a";
}
.icon-utilities:before {
  content: "\e93b";
}
.icon-name:before {
  content: "\e938";
}
.icon-credit_card:before {
  content: "\e939";
}
.icon-weekly_updates:before {
  content: "\e932";
}
.icon-paypal:before {
  content: "\e933";
}
.icon-mobile:before {
  content: "\e934";
}
.icon-devices:before {
  content: "\e935";
}
.icon-community:before {
  content: "\e936";
}
.icon-card:before {
  content: "\e937";
}
.icon-back:before {
  content: "\e930";
}
.icon-save:before {
  content: "\e931";
}
.icon-default_avatar:before {
  content: "\e92f";
}
.icon-close_bold:before {
  content: "\e92c";
}
.icon-phone_login:before {
  content: "\e92e";
}
.icon-close:before {
  content: "\e91a";
}
.icon-facebook_login:before {
  content: "\e928";
}
.icon-google_login:before {
  content: "\e929";
}
.icon-instagram_login:before {
  content: "\e92a";
}
.icon-pass_login:before {
  content: "\e92b";
}
.icon-user_login:before {
  content: "\e92d";
}
.icon-error:before {
  content: "\e924";
}
.icon-success:before {
  content: "\e925";
}
.icon-mail:before {
  content: "\e926";
}
.icon-phone:before {
  content: "\e927";
}
.icon-calendar:before {
  content: "\e923";
}
.icon-upload:before {
  content: "\e920";
}
.icon-sim:before {
  content: "\e921";
}
.icon-bill:before {
  content: "\e922";
}
.icon-write_noreviews:before {
  content: "\e91c";
}
.icon-logout:before {
  content: "\e91d";
}
.icon-unsubscribe:before {
  content: "\e91e";
}
.icon-recharge:before {
  content: "\e91f";
}
.icon-lang:before {
  content: "\e91b";
}
.icon-menu:before {
  content: "\e93c";
}
.icon-allgames:before {
  content: "\e918";
}
.icon-close_menu:before {
  content: "\e919";
}
.icon-categories:before {
  content: "\e917";
}
.icon-arrow_down:before {
  content: "\e900";
}
.icon-write:before {
  content: "\e916";
}
.icon-play:before {
  content: "\e911";
}
.icon-vote_users:before {
  content: "\e912";
}
.icon-reload:before {
  content: "\e913";
}
.icon-rpg:before {
  content: "\e914";
}
.icon-action:before {
  content: "\e901";
}
.icon-adventure:before {
  content: "\e902";
}
.icon-androidgames:before {
  content: "\e903";
}
.icon-arcade:before {
  content: "\e904";
}
.icon-arrow_left:before {
  content: "\e905";
}
.icon-arrow_right:before {
  content: "\e906";
}
.icon-casual:before {
  content: "\e907";
}
.icon-facebook:before {
  content: "\e908";
}
.icon-onlinegames:before {
  content: "\e909";
}
.icon-puzzle:before {
  content: "\e90a";
}
.icon-quest:before {
  content: "\e90b";
}
.icon-racing:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e90d";
}
.icon-simulator:before {
  content: "\e90e";
}
.icon-star:before {
  content: "\e90f";
}
.icon-twitter:before {
  content: "\e910";
}
.icon-youtube:before {
  content: "\e915";
}
