.page-error {
	background-color: #30325E;
	display: flex;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
	flex-wrap: no-wrap;
	flex-direction: column;
	overflow: hidden;
	font-size: 20px;
	width: 100%;

	@media(max-width: 500px) {
		font-size: 16px;
	}

	@media(max-width: 350px) {
		font-size: 14px;
	}
	

	&__back {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		
		background-image: url(../img/error_back.svg);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: auto 75%;
		animation: 40s linear 0s normal none infinite running rotate;

		@media(max-width: 500px) {
			background-size: 90% auto;
			top: -50px;
		}
	}

	&__status {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: center;
		position: relative;
		
		z-index: 1;

		@media(max-width: 500px) {
			top: -50px;
		}

		img {
			height: 9em;
			position: relative;
			bottom: .9em;
			margin: 0 .5em;
		}

		> span {
			font-size: 5em;
			font-weight: bold;
			color: #fff;
		}
	}

	&__msg {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		text-align: center;
		color: #fff;
		margin-top: 1em;

		&_title {
			font-size: 1.1em;
			font-weight: bold;
			margin-bottom: 1em;
		}

		&_text {
			font-size: .7em;
			font-weight: 300;
		}

		.btn {
			border-color: #fff;
			color: #fff;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: .7em;
			margin-top: 2em;
			border-width: 2px;

			i {
				margin-right: 6px;
			}

			&:hover {
				color: #f4ba11;
				border-color: #f4ba11;
				background-color: transparent;
			}
		}
	}
}


@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}